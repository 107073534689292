import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../Header";
import CustomCard from "../CustomeCard";
import CustomInput from "../CustomInput";
import CustomButton from "../Button/Button";
import { useStores } from "../../stores";
import notify from "../../shared/notify";
import "./style.scss"
import ReactExport from "react-export-excel";
import TreasuryCloseBgModal from "../Modal/TreasuryCloseBgModal";
import Loader from "../helper/Loader/loader";
import FormatDate from "../helper/formatDate/formateDate";
import editImg from "../../assets/images/edit.png"

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const TreasuryJourneyIssue = () => {
    const { state } = useLocation();
    const { mainStore } = useStores()
    const navigate = useNavigate()
    const roleName = sessionStorage.getItem("roleName")
    const userName = sessionStorage.getItem("name")
    const [isLoading, setIsLoading] = useState(false)
    const [requestNumber, setRequestNumber] = useState(state?.item?.requestNumber);
    const [requestName, setRequestName] = useState(state?.item?.requesterName);
    const [requesterMail, setRequesterMail] = useState(state?.item?.requesterEmail);
    const [requestEntity, setRequestEntity] = useState(state?.item?.entity);
    const [requestUnit, setRequestUnit] = useState(state?.item?.unit);
    const [approverInfo, setApproverInfo] = useState([
        { approverId: "", approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L1" },
        { approverId: "", approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L2" }
    ]);
    const [bankName, setBankName] = useState(state?.item?.bankName);
    const [bankNameData, setBankNameData] = useState([])
    const [entityId, setEntityId] = useState("")
    const [bankAccountNumber, setBankAccountNumber] = useState(state?.item?.bankAccountNumber);
    const [bankAccountData, setBankAccountData] = useState([])
    const [bankGlCode, setBankGlCode] = useState(state?.item?.bankGlCode);
    const [bankGlCodeData, setBankGlCodeData] = useState([])
    const [refrenceNumber, setRefrenceNumber] = useState(state?.item?.referenceNumber);
    const [applicantName, setApplicantName] = useState(state?.item?.applicantName);
    const [applicantLocation, setApplicantLocation] = useState(state?.item?.applicantLocation);
    const [beneficiaryName, setBeneficiaryName] = useState(state?.item?.beneficiaryName);
    const [beneficiaryLocation, setBeneficiaryLocation] = useState(state?.item?.beneficiaryLocation);
    const [inssuanceDate, setInssuanceDate] = useState(state?.item?.issuanceDate);
    const [expiryDate, setExpiryDate] = useState(state?.item?.expiryDate);
    const [currency, setCurrency] = useState(state?.item?.currency);
    const [claimExpiryDate, setClaimExpiryDate] = useState(state?.item?.claimExpiryDate);
    const [ammount, setAmmount] = useState(state?.item?.amount);
    const [printLocation, setPrintLoation] = useState(state?.item?.printLocation);
    const [typeOfBg, setTypeOfBg] = useState(state?.item?.bgType);
    const [paybleLocation, setPaybleLocation] = useState(state?.item?.payableLocation);
    const [bgCharges, setBgCharges] = useState(state?.item?.bgCharge);
    const [gst, setGst] = useState(state?.item?.gst);
    const [stampcharges, setStampCharges] = useState(state?.item?.stampCharges);
    const [bgChargesGlCode, setBgChargesGlCode] = useState(state?.item?.bgChargesGlCode);
    const [gstGlCode, setGstGlCode] = useState(state?.item?.gstGlCode);
    const [requesterDetailComments, setRequesterDetailComments] = useState(state?.item?.requestorComment)
    const [approverDetailsComment, setApproverDetailsComment] = useState(state?.item?.approverComment)
    const [bankDetailComment, setBankDetailComment] = useState(state?.item?.bankComment)
    const [bankGuaranteeComment, setBankGuaranteeComment] = useState(state?.item?.bankGuaranteeComment)
    const [bankChargesComment, setBankChargesComment] = useState(state?.item?.bankChargesComments)
    const [attachmentComment, setAttachmentComment] = useState(state?.item?.attachmentsComment)

    const [bgText, setBgText] = useState(() => {
        const bgTextString = state?.item?.bgText;
        if (bgTextString) {
            try {
                return JSON.parse(bgTextString);
            } catch (error) {
                console.error("Error parsing bgText JSON:", error);
            }
        }
        return [];
    });
    const [purchaseOrder, setPurchaseOrder] = useState(() => {
        const purchaseOrderString = state?.item?.purchaseOrder;
        if (purchaseOrderString) {
            try {
                return JSON.parse(purchaseOrderString);
            } catch (error) {
                console.error("Error parsing purchaseOrder JSON:", error);
            }
        }
        return [];
    })
    const [getAuditDetail, setGetAuditDetail] = useState([])
    const [showtresuryCloseModal, setShowTresuryCloseModal] = useState(false)
    const [division, setDivision] = useState(state?.item?.lineOfService);
    const [chatComment, setChatComment] = useState("")
    const [startChat, setStartChat] = useState()
    const [chat, setChat] = useState([])
    const [entity, setEntity] = useState([]);
    const [editTreasuryDetails, setEditTreasuryDetails] = useState()
    const [approverList, setApproverList] = useState([]);
    const [cancellations, setCancellations] = useState([]);
    const [amendments, setAmendments] = useState([]);

    const isValidApproverInfo = approverInfo.every(approver => (
        approver.approverName !== "" &&
        approver.approverDepartment !== "" &&
        approver.approverEmail !== "" &&
        approver.approverId !== ""
    ));


    useEffect(() => {
        fetchChatResponse()
        if (state?.item?.approvers?.length > 0) {
            let arr = [];
            state.item.approvers?.map((item) => {
                let obj = {
                    approverId: item?.approver_id,
                    approverName: item?.user?.name,
                    approverEmail: item?.user?.email,
                    approverDepartment: item?.department,
                    approverLevel: item?.approver_level
                }
                arr.push(obj)
            })
            setApproverInfo(arr);
        }
    }, [])


    const fetchEntity = async (e) => {
        await mainStore.getEntity((status, response) => {
            if (response?.meta?.status === "success") {
                setEntity(response?.data?.entitites);
                setBankAccountData([])
                setBankGlCodeData([])
                if (e) {
                    const filterEntityId = response?.data?.entitites?.filter(item => item.name === e)
                    setEntityId(filterEntityId[0]?.entity_code)
                    fetchBankNameData(filterEntityId[0]?.entity_code)
                } else if (e === "") {
                    fetchBankNameData(undefined)
                } else {
                    const filterBankId = response?.data?.entitites?.filter(item => item.name === requestEntity)
                    setEntityId(filterBankId[0]?.entity_code)
                    fetchBankNameData(filterBankId[0]?.entity_code)
                }
            } else {
                notify(response?.meta?.message, "danger");
            }
        },
            (error) => {
                notify(error?.response?.meta?.message, "danger");
            }
        );
    };

    useEffect(() => {
        fetchApproverDetail()
        getBgAuditDetail()
        fetchEntity()
    }, [])

    const handleRequestEntity = (e) => {
        setRequestEntity(e.target.value);
        fetchEntity(e.target.value)
        setBankAccountNumber("")
        setBankGlCode("")
        setBankName("")
    }

    const handleBankName = (e) => {
        setBankName(e.target.value)
        fetchBankAccountDetails(e.target.value)
    }

    const handleBankAccountNumber = (e) => {
        setBankAccountNumber(e.target.value)
        fetchBankGlCode(e.target.value)
    }

    const fetchBankNameData = async (entityId) => {

        mainStore.getBankDetails(entityId, (status, response) => {
            if (response?.meta?.message === "success") {
                setBankNameData(response?.data?.bankList)
            } else {
                notify(response?.meta?.message, "danger")
            }
        }, (error) => {
            notify(error?.response?.meta?.message, "danger")
        })
    }

    const fetchBankAccountDetails = async (num) => {
        let payload = {
            entityCode: entityId,
            bankName: bankName ? bankName : num
        }
        mainStore.getBankAccountDetails(payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                setBankAccountData(response?.data?.data)
            } else {
                notify(response?.data?.meta?.message, "danger")
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
        })
    }

    useEffect(() => {
        if (entityId && bankName) {
            fetchBankAccountDetails()
        }
    }, [entityId, bankName])


    const fetchBankGlCode = async (num) => {
        let payload = {
            bankName: bankName,
            entityCode: entityId,
            accountNumber: bankAccountNumber ? bankAccountNumber : num
        }
        mainStore.getBankGlCode(payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                setBankGlCodeData(response?.data?.data)
            } else {
                notify(response?.data?.meta?.message, "danger")
            }
        }, (err) => {
            notify(err?.response?.data?.meta?.message, "danger")
        })
    }

    useEffect(() => {
        if (bankName && entityId && bankAccountNumber) {
            fetchBankGlCode()
        }
    }, [bankAccountNumber, entityId, bankName])

    const handleApproverChange = (index, field, value) => {
        const newApproverInfo = [...approverInfo];
        newApproverInfo[index][field] = value;
        setApproverInfo(newApproverInfo);
    };

    const closeApproval = () => {
        setShowTresuryCloseModal(true)
    }

    const amendmentData = amendments.map(amendment => ({
        ammendmentId: amendment.id,
        ...(amendment?.bgCharge !== undefined && amendment?.bgCharge !== null && amendment?.bgCharge !== "" && { bgCharge: amendment?.bgCharge }),
        ...(amendment?.gst !== undefined && amendment?.gst !== null && amendment?.gst !== "" && { gst: amendment?.gst }),
        ...(amendment?.stampCharges !== undefined && amendment?.stampCharges !== null && amendment?.stampCharges !== "" && { stampCharges: amendment?.stampCharges }),
        ...(amendment?.bgChargesGlCode !== undefined && amendment?.bgChargesGlCode !== null && amendment?.bgChargesGlCode !== "" && { bgChargesGlCode: amendment?.bgChargesGlCode }),
        ...(amendment?.gstGlCode !== undefined && amendment?.gstGlCode !== null && amendment?.gstGlCode !== "" && { gstGlCode: amendment?.gstGlCode }),
        ...(amendment?.comment !== undefined && amendment?.comment !== null && amendment?.comment !== "" && { comment: amendment?.comment }),
        ...(amendment?.clause !== undefined && amendment?.clause !== null && amendment?.clause !== "" && { clause: amendment?.clause }),
        ...(amendment?.others !== undefined && amendment?.others !== null && amendment?.others !== "" && { others: amendment?.others }),
        ...(amendment?.expiryDate !== undefined && amendment?.expiryDate !== null && amendment?.expiryDate !== "" && { expiryDate: amendment?.expiryDate }),
        ...(amendment?.claimExpiryDate !== undefined && amendment?.claimExpiryDate !== null && amendment?.claimExpiryDate !== "" && { claimExpiryDate: amendment?.claimExpiryDate }),
        attachments: JSON.stringify(amendment.attachments),
    }));

    const cancellationData = cancellations.map(cancellation => ({
        cancellationId: cancellation.id,
        attachments: JSON.stringify(cancellation.attachments),
        ...(cancellation?.bgCharge !== undefined && cancellation?.bgCharge !== null && cancellation?.bgCharge !== "" && { bgCharge: cancellation?.bgCharge }),
        ...(cancellation?.gst !== undefined && cancellation?.gst !== null && cancellation?.gst !== "" && { gst: cancellation?.gst }),
        ...(cancellation?.stampCharges !== undefined && cancellation?.stampCharges !== null && cancellation?.stampCharges !== "" && { stampCharges: cancellation?.stampCharges }),
        ...(cancellation?.bgChargesGlCode !== undefined && cancellation?.bgChargesGlCode !== null && cancellation?.bgChargesGlCode !== "" && { bgChargesGlCode: cancellation?.bgChargesGlCode }),
        ...(cancellation?.gstGlCode !== undefined && cancellation?.gstGlCode !== null && cancellation?.gstGlCode !== "" && { gstGlCode: cancellation?.gstGlCode }),
        ...(cancellation?.comment !== undefined && cancellation?.comment !== null && cancellation?.comment !== "" && { comment: cancellation?.comment }),
        ...(cancellation?.cancellationDate !== undefined && cancellation?.cancellationDate !== null && cancellation?.cancellationDate !== "" && { cancellationDate: cancellation?.cancellationDate }),
    }));


    const UpdateBgRequestByTreasury = async () => {

        if (bgText.length === 0) {
            return notify("Please upload the bgText attachment", "danger")
        }
        if (purchaseOrder.length === 0) {
            return notify("Please upload the purchaseOrder attachment", "danger")
        }

        setIsLoading(true)
        let payload = {
            referenceNumber: refrenceNumber,
            issuanceDate: inssuanceDate,
            ...(bankName !== undefined && bankName !== null && bankName !== "" && { bankName: bankName }),
            ...(bankAccountNumber !== undefined && bankAccountNumber !== null && bankAccountNumber !== "" && { bankAccountNumber: bankAccountNumber }),
            ...(bankGlCode !== undefined && bankGlCode !== null && bankGlCode !== "" && { bankGlCode: bankGlCode }),
            ...(bgCharges !== undefined && bgCharges !== null && bgCharges !== "" && { bgCharge: bgCharges }),
            ...(gst !== undefined && gst !== null && gst !== "" && { gst: gst }),
            ...(stampcharges !== undefined && stampcharges !== null && stampcharges !== "" && { stampCharges: stampcharges }),
            ...(bgChargesGlCode !== undefined && bgChargesGlCode !== null && bgChargesGlCode !== "" && { bgChargesGlCode: bgChargesGlCode }),
            ...(gstGlCode !== undefined && gstGlCode !== null && gstGlCode !== "" && { gstGlCode: gstGlCode }),
            ammendment: amendmentData,
            cancellation: cancellationData,
            bankComment: bankDetailComment !== undefined && bankDetailComment !== null && bankDetailComment !== "" ? bankDetailComment : undefined,
            bankChargesComments: bankChargesComment !== undefined && bankChargesComment !== null && bankChargesComment !== "" ? bankChargesComment : undefined,
            ...(requestEntity !== undefined && requestEntity !== null && requestEntity !== "" && { entity: requestEntity }),
            ...(requestUnit !== undefined && requestUnit !== null && requestUnit !== "" && { unit: requestUnit }),
            lineOfService: division,
            ...(requesterDetailComments !== undefined && requesterDetailComments !== null && requesterDetailComments !== "" && { requestorComment: requesterDetailComments }),
            approver: isValidApproverInfo ? approverInfo : undefined,
            ...(approverDetailsComment !== undefined && approverDetailsComment !== null && approverDetailsComment !== "" && { approverComment: approverDetailsComment }),
            ...(bankGuaranteeComment !== undefined && bankGuaranteeComment !== null && bankGuaranteeComment !== "" && { bankGuaranteeComment: bankGuaranteeComment }),
            ...(bankChargesComment !== undefined && bankChargesComment !== null && bankChargesComment !== "" && { bankChargesComments: bankChargesComment }),
            ...(attachmentComment !== undefined && attachmentComment !== null && attachmentComment !== "" && { attachmentsComment: attachmentComment }),
            ...(applicantName !== undefined && applicantName !== null && applicantName !== "" && { applicantName: applicantName }),
            ...(applicantLocation !== undefined && applicantLocation !== null && applicantLocation !== "" && { applicantLocation: applicantLocation }),
            ...(beneficiaryName !== undefined && beneficiaryName !== null && beneficiaryName !== "" && { beneficiaryName: beneficiaryName }),
            ...(beneficiaryLocation !== undefined && beneficiaryLocation !== null && beneficiaryLocation !== "" && { beneficiaryLocation: beneficiaryLocation }),
            ...(expiryDate !== undefined && expiryDate !== null && expiryDate !== "" && { expiryDate: expiryDate }),
            ...(currency !== undefined && currency !== null && currency !== "" && { currency: currency }),
            ...(claimExpiryDate !== undefined && claimExpiryDate !== null && claimExpiryDate !== "" && { claimExpiryDate: claimExpiryDate }),
            ...(ammount !== undefined && ammount !== null && ammount !== "" && { amount: ammount }),
            ...(printLocation !== undefined && printLocation !== null && printLocation !== "" && { printLocation: printLocation }),
            ...(typeOfBg !== undefined && typeOfBg !== null && typeOfBg !== "" && { bgType: typeOfBg }),
            ...(paybleLocation !== undefined && paybleLocation !== null && paybleLocation !== "" && { payableLocation: paybleLocation }),
            bgText: JSON.stringify(bgText),
            purchaseOrder: JSON.stringify(purchaseOrder)
        }
        await mainStore.updateBgRequestTreasury(state?.item?.requestNumber, payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                notify(response?.data?.meta?.message)
                getBgAuditDetail()
                navigate("/treasury-journey-dashboard")
                setIsLoading(false)
            }
            else {
                notify(response?.data?.meta?.message, "danger")
                setIsLoading(false)
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })
    }


    const getBgAuditDetail = async () => {
        setIsLoading(true)
        let payload = {}
        await mainStore.getBgAudittrail(state?.item?.requestNumber, payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                setGetAuditDetail(response?.data?.data?.audit)
                setIsLoading(false)
            }
            else {
                notify(response?.data?.meta?.message, "danger")
                setIsLoading(false)
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })
    }

    const handlePurchasedownload = async (file) => {
        let payload = {
            filename: file
        }
        callDownloadApi(payload)
    }


    const handleBgTextdownload = async (file) => {
        let payload = {
            filename: file
        }
        callDownloadApi(payload)
    }

    const handlePreviousDownload = async (attachments) => {
        let payload = {
            filename: attachments
        }
        callDownloadApi(payload)
    }
    const handleCancellationDownload = async (attachments) => {
        let payload = {
            filename: attachments
        }

        callDownloadApi(payload)
    }

    const callDownloadApi = async (payload) => {
        setIsLoading(true);
        await mainStore.downloadImage(payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                notify(response?.data?.meta?.message);
                setIsLoading(false);
                const base64File = response?.data?.data?.base64File;
                const mimeType = response?.data?.data?.mimeType;
                const fileNameParts = response?.data?.data?.filename?.split("-");
                const fileName = fileNameParts.slice(1).join("-");
                if (base64File && mimeType) {
                    const downloadLink = document.createElement('a');
                    downloadLink.href = `data:${mimeType};base64,${base64File}`;
                    downloadLink.download = fileName;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                } else {
                    notify("Download link or File type not found", "danger");
                }
            } else {
                notify(response?.data?.meta?.message, "danger");
                setIsLoading(false);
            }
        },
            (error) => {
                notify(error?.response?.data?.meta?.message, "danger");
                setIsLoading(false);
            });
    };

    const toggleChatButton = () => {
        setStartChat(!startChat)
    }

    const handleSendMassege = async () => {
        setIsLoading(true)
        let payload = {
            requestNumber: state?.item?.requestNumber,
            userName: userName,
            userRole: roleName,
            message: chatComment
        }

        await mainStore.sendMassege(payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                notify(response?.data?.meta?.message)
                fetchChatResponse()
                setIsLoading(false)
                setChatComment("")
            } else {
                notify(response?.data?.meta?.message, "danger")
                setIsLoading(false)
                setChatComment("")
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setChatComment("")
        })
    }

    const fetchChatResponse = async () => {
        mainStore.getChatResponse(state?.item?.requestNumber, (status, response) => {
            if (response?.meta?.status === "success") {
                setChat(response?.data?.chats)
            } else {
                notify(response?.meta?.message, "danger")
            }
        }, (error) => {
            notify(error?.response?.meta?.message, "danger")
        })
    }

    const handleEditTreasury = () => {
        setEditTreasuryDetails(!editTreasuryDetails)
    }


    const [lineOfService, setLineOfService] = useState([])

    useEffect(() => {
        fetchLos()
    }, [])

    const fetchLos = async () => {
        await mainStore.getLos((status, res) => {
            if (res?.meta?.status === "success") {
                setLineOfService(res?.data?.allLos);
            } else {
                notify(res?.meta?.message, "danger")
            }
        },
            (error) => {
                notify(error?.response?.meta?.message, "danger")
            }
        );
    }


    const fetchApproverDetail = async (lineOfService) => {
        if (!lineOfService) {
            return;
        }

        let payload = {
            lineOfService,
        };
        await mainStore.getApprovers(payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                setApproverList(response?.data?.data?.users);
            } else {
                notify(response?.data?.meta?.message, "danger");
            }
        },
            (error) => {
                notify(error?.response?.data?.meta?.message, "danger");
            }
        );
    };
    const handleInputChange = (e) => {
        let value = e.target.value;
        setDivision(value);
        fetchApproverDetail(value);
        setApproverInfo([
            { approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L1", approverId: "" },
            { approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L2", approverId: "" }
        ]);
    };
    useEffect(() => {
        if (division) {
            fetchApproverDetail(division);
        }
    }, []);

    const [currencyList] = useState([
        { name: "USD" },
        { name: "EUR" },
        { name: "INR" },
        { name: "CHF" },
    ]);
    const [typeOfBgList] = useState([
        { name: "PBG " },
        { name: "FBG " },
        { name: "EMD " },
        { name: "SBLC" },
    ]);

    const handleBgText = async (e) => {
        setIsLoading(true);
        const files = e.target.files;
        let formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append("file", files[i]);
            let params = "BgRequest";
            await mainStore.uploadfile(params, formData, (response) => {
                if (response?.data?.meta?.status === "success") {
                    notify(response?.data?.meta?.message);
                    setBgText((prevState) => [...prevState, response.data.data]);
                    setIsLoading(false);
                } else {
                    notify(response?.data?.meta?.message, "danger");
                    setIsLoading(false);
                }
            },
                (error) => {
                    notify(error?.response?.data?.meta?.message, "danger");
                    setIsLoading(false);
                }
            );
            formData.delete("file");
            formData.delete("filepath");
        }
    };

    const handlePurchaseOrder = async (e) => {
        setIsLoading(true);
        const files = e.target.files;
        let formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append("file", files[i]);
            let params = "BgRequest";
            await mainStore.uploadfile(params, formData, (response) => {
                if (response?.data?.meta?.status === "success") {
                    notify(response?.data?.meta?.message);
                    setPurchaseOrder((prevState) => [...prevState, response.data.data]);
                    setIsLoading(false);
                } else {
                    notify(response?.data?.meta?.message, "danger");
                    setIsLoading(false);
                }
            },
                (error) => {
                    notify(error?.response?.data?.meta?.message, "danger");
                    setIsLoading(false);
                }
            );
            formData.delete("file");
            formData.delete("filepath");
        }
    };


    const handleFileChange = async (e, index) => {
        setIsLoading(true);
        const files = e.target.files;
        let formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i]);
            let params = 'BgRequest';
            await mainStore.uploadfile(params, formData, (response) => {
                if (response?.data?.meta?.status === "success") {
                    notify(response?.data?.meta?.message);
                    const updatedAmendments = amendments.map((amendment, i) =>
                        i === index ? { ...amendment, attachments: [...amendment.attachments, response.data.data] } : amendment
                    );
                    setAmendments(updatedAmendments);
                } else {
                    notify(response?.data?.meta?.message, "danger");
                }
                setIsLoading(false);
            },
                (error) => {
                    notify(error?.response?.data?.meta?.message, "danger");
                    setIsLoading(false);
                });
            formData.delete('file');
        }
    };


    const handleCancellationFileChange = async (e, index) => {
        setIsLoading(true);
        const files = e.target.files;
        let formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i]);
            let params = 'BgRequest';
            await mainStore.uploadfile(params, formData, (response) => {
                if (response?.data?.meta?.status === "success") {
                    notify(response?.data?.meta?.message);
                    const updatedCancellations = cancellations.map((cancellation, i) =>
                        i === index ? { ...cancellation, attachments: [...cancellation.attachments, response.data.data] } : cancellation
                    );
                    setCancellations(updatedCancellations);
                } else {
                    notify(response?.data?.meta?.message, "danger");
                }
                setIsLoading(false);
            },
                (error) => {
                    notify(error?.response?.data?.meta?.message, "danger");
                    setIsLoading(false);
                });
            formData.delete('file');
        }
    };

    useEffect(() => {
        if (state?.item?.bgrequestCancellation) {
            setCancellations(
                state.item.bgrequestCancellation.map(cancellation => ({
                    id: cancellation.id,
                    bgCharge: cancellation.bgCharge || '',
                    gst: cancellation.gst || '',
                    stampCharges: cancellation.stampCharges || '',
                    bgChargesGlCode: cancellation.bgChargesGlCode || '',
                    gstGlCode: cancellation.gstGlCode || '',
                    comment: cancellation.comment || '',
                    attachments: cancellation.attachments ? JSON.parse(cancellation.attachments) : [],
                    cancellationDate: cancellation.cancellationDate ? cancellation.cancellationDate?.split('T')[0] : ''
                }))
            );
        }
    }, [state?.item?.bgrequestCancellation]);


    const handleCancellationInputData = (index, field, value) => {
        const updatedCancellations = cancellations.map((cancellation, i) =>
            i === index ? { ...cancellation, [field]: value } : cancellation
        );
        setCancellations(updatedCancellations);
    };

    useEffect(() => {
        if (state?.item?.bgrequestAmendment) {
            setAmendments(
                state.item.bgrequestAmendment.map(amendment => ({
                    id: amendment.id,
                    bgCharge: amendment.bgCharge || '',
                    gst: amendment.gst || '',
                    stampCharges: amendment.stampCharges || '',
                    bgChargesGlCode: amendment.bgChargesGlCode || '',
                    gstGlCode: amendment.gstGlCode || '',
                    comment: amendment.comment || '',
                    attachments: amendment.attachments ? JSON.parse(amendment.attachments) : [],
                    expiryDate: amendment.expiryDate ? amendment.expiryDate?.split('T')[0] : '',
                    claimExpiryDate: amendment.claimExpiryDate ? amendment.claimExpiryDate?.split('T')[0] : '',
                    clause: amendment.clause || '',
                    others: amendment.others || ''
                }))
            );
        }
    }, [state?.item?.bgrequestAmendment]);

    const handleAmendmentInputData = (index, field, value) => {
        const updatedAmendments = amendments.map((amendment, i) =>
            i === index ? { ...amendment, [field]: value } : amendment
        );
        setAmendments(updatedAmendments);
    };

    const getFormattedDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    return (
        <div className="main-container">
            <div className="right-view">
                {isLoading && <Loader />}
                <Header title={"Treasury > Bg Issue"} />
                <div className="row">
                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Requester"}
                            cardboldtitle={"Details"}
                            toprightview={editTreasuryDetails ? "" : <img src={editImg} alt="" className="edit-btn" onClick={handleEditTreasury} />}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={true}
                                            label="Request Number"
                                            placeholder="Request Number"
                                            value={requestNumber}
                                            onChange={(e) => setRequestNumber(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={true}
                                            label="Requester Name"
                                            placeholder="Requester Name"
                                            value={requestName}
                                            onChange={(e) => setRequestName(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Requester mail ID"
                                            placeholder="Requester mail ID"
                                            value={requesterMail}
                                            onChange={(e) => setRequesterMail(e.target.value)}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <div className="custominput-container">
                                            <div className="custom-input">
                                                <label htmlFor="">Entity </label>
                                                <div style={{ position: "relative" }}>
                                                    <select
                                                        className="form-control"
                                                        value={requestEntity}
                                                        disabled={!editTreasuryDetails}
                                                        onChange={handleRequestEntity}
                                                    >
                                                        <option value="">Select Entity</option>
                                                        {entity.map((entity, index) => (
                                                            <option key={index} value={entity.name}>
                                                                {entity.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <i className="fas fa-chevron-down approver-drop-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <CustomInput label="Unit" placeholder="Unit"
                                            value={requestUnit}
                                            readOnly={editTreasuryDetails ? false : true}
                                            onChange={(e) => setRequestUnit(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <div className="custominput-container">
                                            <div className="custom-input">
                                                <label htmlFor="">Line Of Service </label>
                                                <div style={{ position: "relative" }}>
                                                    <select
                                                        className="form-control"
                                                        disabled={!editTreasuryDetails}
                                                        value={division}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option value="">Select Line Of Service </option>
                                                        {lineOfService.map((item, index) => (
                                                            <option key={index} value={item.name}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <i className="fas fa-chevron-down approver-drop-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={editTreasuryDetails ? false : true}
                                            label="Comment"
                                            placeholder="Comment"
                                            value={requesterDetailComments}
                                            onChange={(e) => setRequesterDetailComments(e.target.value)}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Approver"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div>
                                    {approverInfo.map((approver, index) => (
                                        <div key={index} className="row">
                                            <div className="col-md-4">
                                                <div className="custominput-container">
                                                    <div className="custom-input">
                                                        <label htmlFor={`approverName${index}`}>
                                                            {/* Approver L{index + 1} Name{" "}
                                                            <span style={{ color: "red" }}>*</span> */}
                                                            {index === 0 && <>Approver Purchase <span style={{ color: 'red' }}>*</span></>}
                                                            {index === 1 && <>Approver Finance <span style={{ color: 'red' }}>*</span></>}
                                                        </label>
                                                        <div style={{ position: "relative" }}>
                                                            <select
                                                                id={`approverName${index}`}
                                                                className="form-control"
                                                                value={approver.approverName}
                                                                disabled={!editTreasuryDetails}
                                                                onChange={(e) => {
                                                                    const selectedApprover = approverList.find(
                                                                        (approver) =>
                                                                            approver.name === e.target.value
                                                                    );
                                                                    handleApproverChange(index, "approverName", e.target.value);
                                                                    handleApproverChange(index, "approverId", selectedApprover.id);
                                                                    handleApproverChange(index, "approverEmail", selectedApprover.email);
                                                                    handleApproverChange(index, "approverDepartment", selectedApprover.user_department);
                                                                }}
                                                            >
                                                                <option value="">Select Approver</option>
                                                                {index === 0 &&
                                                                    approverList.filter((approver) => approver.user_department === "Purchase").map((filteredApprover, filteredIndex) => (
                                                                        <option key={filteredIndex} value={filteredApprover.name}> {filteredApprover.name} </option>
                                                                    ))}
                                                                {index === 1 &&
                                                                    approverList.filter((approver) => approver.user_department === "Finance").map((filteredApprover, filteredIndex) => (
                                                                        <option key={filteredIndex} value={filteredApprover.name}>{filteredApprover.name} </option>
                                                                    ))}
                                                            </select>
                                                            <i className="fas fa-chevron-down approver-drop-down"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <CustomInput
                                                    require={true}
                                                    readOnly={true}
                                                    label="Department"
                                                    value={approver.approverDepartment}
                                                    onChange={(e) => handleApproverChange(index, "approverDepartment", e.target.value)}
                                                    placeholder="Department"
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <CustomInput
                                                    require={true}
                                                    readOnly={true}
                                                    label="Mail ID"
                                                    value={approver.approverEmail}
                                                    onChange={(e) => handleApproverChange(index, "approverEmail", e.target.value)}
                                                    placeholder="Mail ID"
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    <div className="col-md-4">
                                        <CustomInput
                                            readOnly={editTreasuryDetails ? false : true}
                                            label="Comment"
                                            placeholder="Comment"
                                            value={approverDetailsComment}
                                            onChange={(e) => setApproverDetailsComment(e.target.value)}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Bank"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="custominput-container">
                                            <div className="custom-input">
                                                <label htmlFor="" >Bank Name </label>
                                                <div style={{ position: 'relative' }}>
                                                    <select
                                                        className="form-control"
                                                        value={bankName}
                                                        onChange={handleBankName}
                                                    >
                                                        <option value="">Select Bank Name</option>
                                                        {bankNameData?.map((entity, index) => (
                                                            <option key={index} value={entity}>
                                                                {entity}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <i className="fas fa-chevron-down approver-drop-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="custominput-container">
                                            <div className="custom-input">
                                                <label htmlFor="" >Bank Account Number</label>
                                                <div style={{ position: 'relative' }}>
                                                    <select
                                                        className="form-control"
                                                        value={bankAccountNumber}
                                                        onChange={handleBankAccountNumber}
                                                    >
                                                        <option value="">Select Bank Account Number</option>
                                                        {bankAccountData?.map((item, index) => (
                                                            <option key={index} value={item?.bank_account_number}>
                                                                {item?.bank_account_number}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <i className="fas fa-chevron-down approver-drop-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="custominput-container">
                                            <div className="custom-input">
                                                <label htmlFor="" >Bank GL Code</label>
                                                <div style={{ position: 'relative' }}>
                                                    <select
                                                        className="form-control"
                                                        value={bankGlCode}
                                                        onChange={(e) => setBankGlCode(e.target.value)}
                                                    >
                                                        <option value="">Select Bank GL Code</option>
                                                        {bankGlCodeData?.map((item, index) => (
                                                            <option key={index} value={item?.glcode}>
                                                                {item?.glcode}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <i className="fas fa-chevron-down approver-drop-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Comment"
                                            placeholder="Comment"
                                            value={bankDetailComment}
                                            onChange={(e) => setBankDetailComment(e.target.value)}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Bank Guarantee"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Reference Number"
                                            placeholder="Reference Number"
                                            value={refrenceNumber}
                                            onChange={(e) => setRefrenceNumber(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <div className="custominput-container">
                                            <div className="custom-input">
                                                <label htmlFor=""> Applicant Name <span style={{ color: "red" }}>*</span></label>
                                                <div style={{ position: "relative" }}>
                                                    <select
                                                        className="form-control"
                                                        value={applicantName}
                                                        disabled={!editTreasuryDetails}
                                                        onChange={(e) => { setApplicantName(e.target.value) }}
                                                    >
                                                        <option value="">Select Applicant Name</option>
                                                        {entity.map((entity, index) => (
                                                            <option key={index} value={entity.name}>
                                                                {entity.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <i className="fas fa-chevron-down approver-drop-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={editTreasuryDetails ? false : true}
                                            label="Applicant Location"
                                            placeholder="Applicant Location"
                                            value={applicantLocation}
                                            onChange={(e) => setApplicantLocation(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={editTreasuryDetails ? false : true}
                                            label="Beneficiary Name"
                                            placeholder="Beneficiary Name"
                                            value={beneficiaryName}
                                            onChange={(e) => setBeneficiaryName(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={editTreasuryDetails ? false : true}
                                            label="Beneficiary Location"
                                            placeholder="Beneficiary Location"
                                            value={beneficiaryLocation}
                                            onChange={(e) => setBeneficiaryLocation(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Issuance Date"
                                            placeholder="Issuance Date"
                                            type="date"
                                            value={inssuanceDate && getFormattedDate(inssuanceDate)}
                                            // min={new Date().toISOString()?.split('T')[0]}
                                            onChange={(e) => setInssuanceDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={editTreasuryDetails ? false : true}
                                            label="Expiry Date"
                                            type="date"
                                            value={expiryDate && getFormattedDate(expiryDate)}
                                            onChange={(e) => setExpiryDate(e.target.value)}
                                            placeholder="Expiry Date"
                                            min={new Date().toISOString()?.split("T")[0]}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <div className="custominput-container">
                                            <div className="custom-input">
                                                <label htmlFor="">Currency </label>
                                                <div style={{ position: "relative" }}>
                                                    <select
                                                        className="form-control"
                                                        value={currency}
                                                        disabled={!editTreasuryDetails}
                                                        onChange={(e) => setCurrency(e.target.value)}
                                                    >
                                                        <option value="">Select Currency</option>
                                                        {currencyList.map((item, index) => (
                                                            <option key={index} value={item.name}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <i className="fas fa-chevron-down approver-drop-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={editTreasuryDetails ? false : true}
                                            label="Claim Expiry Date"
                                            value={claimExpiryDate && getFormattedDate(claimExpiryDate)}
                                            type="date"
                                            min={expiryDate}
                                            onChange={(e) => setClaimExpiryDate(e.target.value)}
                                            placeholder="Claim Expiry Date"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={editTreasuryDetails ? false : true}
                                            label="Amount"
                                            value={ammount}
                                            onChange={(e) => setAmmount(e.target.value)}
                                            placeholder="Amount"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={editTreasuryDetails ? false : true}
                                            label="Print Location"
                                            value={printLocation}
                                            onChange={(e) => setPrintLoation(e.target.value)}
                                            placeholder="Print Location"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <div className="custominput-container">
                                            <div className="custom-input">
                                                <label htmlFor="">Type of BG </label>
                                                <div style={{ position: "relative" }}>
                                                    <select
                                                        className="form-control"
                                                        value={typeOfBg}
                                                        disabled={!editTreasuryDetails}
                                                        onChange={(e) => setTypeOfBg(e.target.value)}
                                                    >
                                                        <option value="">Select Type of BG</option>
                                                        {typeOfBgList.map((item, index) => (
                                                            <option key={index} value={item.name}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <i className="fas fa-chevron-down approver-drop-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Payable Location"
                                            value={paybleLocation}
                                            readOnly={editTreasuryDetails ? false : true}
                                            onChange={(e) => setPaybleLocation(e.target.value)}
                                            placeholder="Payable Location"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={editTreasuryDetails ? false : true}
                                            label="Comment"
                                            placeholder="Comment"
                                            value={bankGuaranteeComment}
                                            onChange={(e) => setBankGuaranteeComment(e.target.value)}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Bank Charges"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="BG Charges"
                                            value={bgCharges}
                                            onChange={(e) => setBgCharges(e.target.value)}
                                            placeholder="BG Charges"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput label="GST" placeholder="GST"
                                            value={gst}
                                            onChange={(e) => setGst(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Stamp Charges"
                                            value={stampcharges}
                                            onChange={(e) => setStampCharges(e.target.value)}
                                            placeholder="Stamp Charges"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="BG Charges GL Code"
                                            value={bgChargesGlCode}
                                            onChange={(e) => setBgChargesGlCode(e.target.value)}
                                            placeholder="BG Charges GL Code"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="GST GL Code"
                                            value={gstGlCode}
                                            onChange={(e) => setGstGlCode(e.target.value)}
                                            placeholder="GST GL Code"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Comment"
                                            placeholder="Comment"
                                            value={bankChargesComment}
                                            onChange={(e) => setBankChargesComment(e.target.value)}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardboldtitle={"Attachments"}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="BG Text"
                                            require={true}
                                            readOnly={!editTreasuryDetails}
                                            placeholder="BG Text"
                                            {...(editTreasuryDetails ? { type: "file", onChange: handleBgText } : { type: "text" })}
                                        />
                                        <div className="uploaded-images">
                                            {bgText.map((bgText, index) => (
                                                <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: "8px", }} onClick={() => handleBgTextdownload(bgText)} className="url-download mb-2">
                                                    {bgText}
                                                </div>
                                            ))}
                                        </div>
                                    </div>


                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Purchase Order/ RFP"
                                            readOnly={!editTreasuryDetails}
                                            {...(editTreasuryDetails ? { type: "file", onChange: handlePurchaseOrder } : { type: "text" })}
                                            placeholder="Browse File"
                                        />
                                        <div className="uploaded-images">
                                            {purchaseOrder.map((order, index) => (
                                                <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: '8px' }} onClick={() => handlePurchasedownload(order)} className="url-download mb-2">
                                                    {order}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={editTreasuryDetails ? false : true}
                                            label="Comment"
                                            placeholder="Comment"
                                            value={attachmentComment}
                                            onChange={(e) => setAttachmentComment(e.target.value)}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>


                    {amendments.length > 0 && (
                        <div className="col-md-12">
                            {amendments.map((amendment, index) => (
                                <CustomCard
                                    key={amendment.id}
                                    cardboldtitle={`Amendment ${index + 1}`}
                                    cardbody={
                                        <div className="row">
                                            <div className="col-md-3">
                                                <CustomInput
                                                    readOnly={!editTreasuryDetails}
                                                    label="Expiry Date"
                                                    placeholder="Expiry Date"
                                                    type="date"
                                                    value={amendment.expiryDate}
                                                    onChange={(e) => handleAmendmentInputData(index, 'expiryDate', e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    readOnly={!editTreasuryDetails}
                                                    label="Claim Expiry Date"
                                                    placeholder="Claim Expiry Date"
                                                    type="date"
                                                    min={amendment.expiryDate}
                                                    value={amendment.claimExpiryDate}
                                                    onChange={(e) => handleAmendmentInputData(index, 'claimExpiryDate', e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    readOnly={!editTreasuryDetails}
                                                    label="Clause"
                                                    placeholder="Clause"
                                                    value={amendment.clause}
                                                    onChange={(e) => handleAmendmentInputData(index, 'clause', e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    readOnly={!editTreasuryDetails}
                                                    label="Others"
                                                    placeholder="Others"
                                                    value={amendment.others}
                                                    onChange={(e) => handleAmendmentInputData(index, 'others', e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    require={true}
                                                    label="Attachment"
                                                    placeholder="Browse file"
                                                    readOnly={!editTreasuryDetails}
                                                    {...(editTreasuryDetails ? { type: "file", onChange: (e) => handleFileChange(e, index) } : { type: "text" })}
                                                />
                                                {amendment.attachments.map((item, i) => (
                                                    <div key={i} style={{ cursor: "pointer", color: "#65a6fa" }} onClick={() => handlePreviousDownload(item)} className="mb-3">
                                                        {item}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    label="BG Charges"
                                                    value={amendment.bgCharge}
                                                    onChange={(e) => handleAmendmentInputData(index, 'bgCharge', e.target.value)}
                                                    placeholder="BG Charges"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    label="GST"
                                                    placeholder="GST"
                                                    value={amendment.gst}
                                                    onChange={(e) => handleAmendmentInputData(index, 'gst', e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    label="Stamp Charges"
                                                    value={amendment.stampCharges}
                                                    onChange={(e) => handleAmendmentInputData(index, 'stampCharges', e.target.value)}
                                                    placeholder="Stamp Charges"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    label="BG Charges GL Code"
                                                    value={amendment.bgChargesGlCode}
                                                    onChange={(e) => handleAmendmentInputData(index, 'bgChargesGlCode', e.target.value)}
                                                    placeholder="BG Charges GL Code"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    label="GST GL Code"
                                                    value={amendment.gstGlCode}
                                                    onChange={(e) => handleAmendmentInputData(index, 'gstGlCode', e.target.value)}
                                                    placeholder="GST GL Code"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    readOnly={!editTreasuryDetails}
                                                    label="Comment"
                                                    placeholder="Comment"
                                                    value={amendment.comment}
                                                    onChange={(e) => handleAmendmentInputData(index, 'comment', e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    }
                                />
                            ))}
                        </div>
                    )}

                    {cancellations.length > 0 && (
                        <div className="col-md-12">
                            {cancellations.map((cancellation, index) => (
                                <CustomCard
                                    key={cancellation.id}
                                    cardboldtitle={`Cancellation ${index + 1}`}
                                    cardbody={
                                        <div className="row">
                                            <div className="col-md-3">
                                                <CustomInput
                                                    readOnly={!editTreasuryDetails}
                                                    label="Claim Expiry Date"
                                                    placeholder="Claim Expiry Date"
                                                    type="date"
                                                    value={cancellation.cancellationDate}
                                                    onChange={(e) =>
                                                        handleCancellationInputData(index, 'cancellationDate', e.target.value)
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    require={true}
                                                    label="Attachment"
                                                    placeholder="Browse file"
                                                    readOnly={!editTreasuryDetails}
                                                    {...(editTreasuryDetails ? { type: "file", onChange: (e) => handleCancellationFileChange(e, index) } : { type: "text" })}

                                                />
                                                {cancellation.attachments.map((item, i) => (
                                                    <div key={i} style={{ cursor: 'pointer', color: '#65a6fa' }} onClick={() => handleCancellationDownload(item)} className="url-download">
                                                        {item}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    label="BG Charges"
                                                    value={cancellation.bgCharge}
                                                    onChange={(e) =>
                                                        handleCancellationInputData(index, 'bgCharge', e.target.value)
                                                    }
                                                    placeholder="BG Charges"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    label="GST"
                                                    placeholder="GST"
                                                    value={cancellation.gst}
                                                    onChange={(e) =>
                                                        handleCancellationInputData(index, 'gst', e.target.value)
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    label="Stamp Charges"
                                                    value={cancellation.stampCharges}
                                                    onChange={(e) =>
                                                        handleCancellationInputData(index, 'stampCharges', e.target.value)
                                                    }
                                                    placeholder="Stamp Charges"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    label="BG Charges GL Code"
                                                    value={cancellation.bgChargesGlCode}
                                                    onChange={(e) =>
                                                        handleCancellationInputData(index, 'bgChargesGlCode', e.target.value)
                                                    }
                                                    placeholder="BG Charges GL Code"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    label="GST GL Code"
                                                    value={cancellation.gstGlCode}
                                                    onChange={(e) =>
                                                        handleCancellationInputData(index, 'gstGlCode', e.target.value)
                                                    }
                                                    placeholder="GST GL Code"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    readOnly={!editTreasuryDetails}
                                                    label="Comment"
                                                    placeholder="Comment"
                                                    value={cancellation.comment}
                                                    onChange={(e) =>
                                                        handleCancellationInputData(index, 'comment', e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    }
                                />
                            ))}
                        </div>
                    )}


                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Audit"}
                            cardboldtitle={" trail"}
                            toprightview={
                                <button className="export-btn">
                                    <ExcelFile element={<div> <img width={20} className="me-2" src={require("../../assets/images/excel.png")} />Export</div>}>
                                        <ExcelSheet data={getAuditDetail?.map((item, i) => ({
                                            ...item, index: i + 1, role: item?.role,
                                            created_at: item?.created_at && `${item.created_at?.split("T")[0]} ${item.created_at?.split("T")[1]?.split(".")[0].substring(0, 5)}`,
                                        }))} name="Sheet">
                                            <ExcelColumn label="Status Date & Time" value="created_at" />
                                            <ExcelColumn label="Name" value="userName" />
                                            <ExcelColumn label="Role" value="role" />
                                            <ExcelColumn label="Action Required" value="status" />
                                        </ExcelSheet>
                                    </ExcelFile>
                                </button>
                            }
                            cardbody={
                                <React.Fragment>
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead class="table-customlight">
                                                <tr>
                                                    <th className="auditTrail">Status Date & Time</th>
                                                    <th className="auditTrail">Name</th>
                                                    <th className="auditTrail">Role</th>
                                                    <th className="auditTrail">Action Required</th>
                                                </tr>
                                            </thead>
                                            {getAuditDetail?.length > 0 && (
                                                <tbody>
                                                    {getAuditDetail?.map((item, i) => {
                                                        return (
                                                            <tr key={item?.requestNumber}>
                                                                <td scope="row"><FormatDate dateString={item?.created_at} /></td>
                                                                <td>{item?.userName}</td>
                                                                <td>{item?.role}</td>
                                                                <td className="status-box"><span className="status"></span>{item?.status}</td>

                                                            </tr>)
                                                    })}
                                                </tbody>)}
                                        </table>
                                    </div>
                                </React.Fragment>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={""}
                            cardboldtitle={"Chat"}
                            cardbody={
                                <div className="row">
                                    {chat?.length === 0 && <>
                                        <div className="col-md-12 chat-button">
                                            <CustomButton style={"btn--outline me-3"} children={"Initiate Chat Trail"} onClick={toggleChatButton} />
                                        </div></>}

                                    {(startChat || (chat?.length > 0)) && <>
                                        {chat?.length > 0 && <>
                                            {
                                                chat.map((item, i) => (
                                                    <div key={i} className="col-md-12 chat-left">
                                                        <div className="chat-container">
                                                            <h4 className="chat-username">{item?.userName}({item?.userRole})</h4>
                                                            <div className="chat-username-box">
                                                                <p className="chat-massege">{item?.message}</p>
                                                                <p className="chat-time"><FormatDate dateString={item?.created_at} /></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            } </>}
                                        <div className="col-md-6 " style={{ width: "44%" }}>
                                            <CustomInput
                                                label="Comment Box"
                                                value={chatComment}
                                                onChange={(e) => setChatComment(e.target.value)}
                                                placeholder="Comment Box"
                                            />
                                        </div>
                                        <div className="col-md-2 chat-button">
                                            <CustomButton style={"btn--outline me-3"} children={"Send"} onClick={handleSendMassege} />
                                        </div>
                                    </>}
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12 text-end">
                        <CustomButton style={"btn--outline me-3"} children={"Save"} onClick={UpdateBgRequestByTreasury} />
                        <CustomButton children={"Close"} onClick={closeApproval} />
                    </div>
                </div>
            </div>
            <TreasuryCloseBgModal
                show={showtresuryCloseModal}
                hide={() => {
                    setShowTresuryCloseModal(false)
                }}
                requestNumber={state?.item?.requestNumber}
            />
        </div>
    );
};

export default TreasuryJourneyIssue;
