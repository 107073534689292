import React, { useState, useEffect } from "react";
import "./style.scss";
import Header from "../../../component/Header";
import CustomCard from "../../../component/CustomeCard";
import CustomInput from "../../../component/CustomInput";
import CustomButton from "../../../component/Button/Button";
import notify from "../../../shared/notify";
import { useStores } from "../../../stores";
import { useNavigate } from "react-router-dom";
import isEmail from 'validator/lib/isEmail';
import Loader from "../../../component/helper/Loader/loader";

const RJLCIssuance = () => {
  const { mainStore } = useStores()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [requestNumber, setRequestNumber] = useState();
  const [requestName] = useState(sessionStorage?.name);
  const [requesterMail] = useState(sessionStorage?.email);
  const [requestEntity, setRequestEntity] = useState([]);
  const [requestUnit, setRequestUnit] = useState();
  const [approverInfo, setApproverInfo] = useState([
    { approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L1", approverId: "" },
    { approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L2", approverId: "" }
  ]);
  const [bankName, setBankName] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState();
  const [bankGlCode, setBankGlCode] = useState();
  const [refrenceNumber, setRefrenceNumber] = useState();
  const [typeOfLc, setTypeOfLc] = useState("");
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [beneficiaryLocation, setBeneficiaryLocation] = useState("");
  const [inssuanceDate, setInssuanceDate] = useState("");
  const [expiryDate, setExpiryDate] = useState();
  const [currency, setCurrency] = useState();
  const [claimExpiryDate] = useState();
  const [ammount, setAmmount] = useState();
  const [nameOfApplicant, setNameOfApplicant] = useState();
  const [applicantAddress, setApplicantAddress] = useState();
  const [bgCharges, setBgCharges] = useState();
  const [gst, setGst] = useState();
  const [bgChargesGlCode, setBgChargesGlCode] = useState();
  const [gstGlCode, setGstGlCode] = useState();
  const [placeOfExpiry, setPlaceOfExpiry] = useState("")
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState("")
  const [purchaseOrderDate, setPurchaseOrderDate] = useState("")
  const [beneficiaryBankName, setBeneficiaryBankName] = useState("")
  const [beneficiaryBankAddress, setBeneficiaryBankAddress] = useState("")
  const [beneficiaryBankSwiftCode, setBeneficiaryBankSwiftCode] = useState()
  const [modeOfShipment, setModeOfShipment] = useState("")
  const [quantityTolerance, setQuantityTolerance] = useState("")
  const [valueTolerance, setValueTolerance] = useState()
  const [draftsAt, setDraftAt] = useState("")
  const [dispatchFrom, setDispatchFrom] = useState("")
  const [hsnCode, setHsnCode] = useState("")
  const [dispatchDate, setDispatchDate] = useState("")
  const [descriptionOfGood, setDescriptionOfGood] = useState("")
  const [ibanCode, setIbanCode] = useState("")
  const [approverList, setApproverList] = useState([])
  const [LcDraft, setLcDraft] = useState([]);
  const [purchaseOrder, setPurchaseOrder] = useState([]);
  const [proformaInvoice, setProformaInvoice] = useState([]);
  const [creditReport, setCreditReport] = useState([]);
  const [entity, setEntity] = useState("")
  const isValidApproverInfo = approverInfo.every(approver => (
    approver.approverName !== "" &&
    approver.approverDepartment !== "" &&
    approver.approverEmail !== "" &&
    approver.approverId !== ""
  ));

  const [lineOfService , setLineOfService] = useState([])
  const [division, setDivision] = useState(sessionStorage.getItem("line_of_service"));
  const [selectedTypeOfLC, setSelectedTypeOfLC] = useState(false)
  const [currencyList, setCurrencyList] = useState([])

  useEffect(() => {

    if (!typeOfLc) {
      return
    }

    if (typeOfLc === "ILC  ") {
      setSelectedTypeOfLC(true);
      setCurrencyList([{ name: 'INR' }]);
    } else if (typeOfLc === "FLC  ") {
      setSelectedTypeOfLC(false)
      setCurrencyList([
        { name: "USD" },
        { name: "EUR" },
        { name: "CHF" }
      ]);
    } else {
      setSelectedTypeOfLC(false)
      setCurrencyList([
        { name: "USD" },
        { name: "EUR" },
        { name: "INR" },
        { name: "CHF" }
      ]);
    }

  }, [typeOfLc]);

  const [typeOfLcList] = useState([
    { name: "ILC  " },
    { name: "FLC  " },
    { name: "Others " }
  ])

  const [requesterDetailComments, setRequesterDetailComments] = useState("")
  const [approverDetailsComment, setApproverDetailsComment] = useState("")
  const [bankDetailComment, setBankDetailComment] = useState("")
  const [letterOfCreditDetailComment, setLetterOfCreditDetailComment] = useState("")
  const [bankChargesComment, setBankChargesComment] = useState("")
  const [attachmentComment, setAttachmentComment] = useState("")
  const Viewer = sessionStorage.getItem("role")


  useEffect(() => {
    fetchApproverDetail()
    fetchEntity()
  }, [])

  const fetchEntity = async () => {
    await mainStore.getEntity((status, response) => {
      if (response?.meta?.status === "success") {
        setRequestEntity(response?.data?.entitites)
      } else {
        notify(response?.meta?.message, "danger");
      }
    },
      (error) => {
        notify(error?.response?.meta?.message, "danger");
      }
    );
  }

  const fetchApproverDetail = async (lineOfService) => {
    if (!lineOfService) {
      return;
    }

    let payload = {
      lineOfService
    };
    await mainStore.getApprovers(payload, (response) => {
      if (response?.data?.meta?.status === "success") {
        setApproverList(response?.data?.data?.users)
      } else {
        notify(response?.data?.meta?.message, "danger");
      }
    },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
      }
    );
  }
  const handleInputChange = (e) => {
    let value = e.target.value
    setDivision(value)
    fetchApproverDetail(value)
    setApproverInfo([
      { approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L1", approverId: "" },
      { approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L2", approverId: "" }
  ]);
  }
  useEffect(() => {
    if (division) {
      fetchApproverDetail(division);
    }
  }, []);

  const handleApproverChange = (index, field, value) => {
    const updatedApproverInfo = [...approverInfo];
    updatedApproverInfo[index][field] = value;
    setApproverInfo(updatedApproverInfo);
  };


  const submitForApproval = async () => {
    if (
      requestName === "undefined" ||
      requestName === "" ||
      requestName === "null"
    ) {
      notify("Fill The Request Name", "danger");
      return
    }
    if (
      requestEntity === "undefined" ||
      requestEntity === "" ||
      requestEntity === "null"
    ) {
      notify("Fill The Request Entity", "danger");
      return
    }
    if (
      requestUnit === "undefined" ||
      requestUnit === "" ||
      requestUnit === "null"
    ) {
      notify("Fill The Request Unit", "danger");
      return
    }

    if (
      beneficiaryName === "undefined" ||
      beneficiaryName === "" ||
      beneficiaryName === "null"
    ) {
      notify("Fill The Beneficiary Name", "danger");
      return
    }
    if (
      beneficiaryLocation === "undefined" ||
      beneficiaryLocation === "" ||
      beneficiaryLocation === "null"
    ) {
      notify("Fill The Beneficiary Location", "danger");
      return
    }
    if (
      expiryDate === "undefined" ||
      expiryDate === "" ||
      expiryDate === "null"
    ) {
      notify("Fill The Expiry Date", "danger");
      return
    }
    if (currency === "undefined" || currency === "" || currency === "null") {
      notify("Fill The Currency", "danger");
      return
    }
    if (
      claimExpiryDate === "undefined" ||
      claimExpiryDate === "" ||
      claimExpiryDate === "null"
    ) {
      notify("Fill The Claim Expiry Date", "danger");
      return
    }
    if (ammount === "undefined" || ammount === "" || ammount === "null") {
      notify("Fill The Amount", "danger");
    }

    if (!purchaseOrder.length) {
      notify("Upload The Purchase Order", "danger");
      return;
    }
    if (typeOfLc === "FLC  " && creditReport.length === 0) {
      return notify("Please upload the credit report attachment", "danger")
    }
    if (!proformaInvoice.length) {
      notify("Upload The Proforma Invoice", "danger");
      return;
    }
    if (!LcDraft.length) {
      notify("Upload The LcDraft", "danger");
      return;
    }

    setIsLoading(true)

    let payload = {
      requesterName: requestName,
      requesterEmail: requesterMail,
      entity: entity,
      unit: requestUnit,
      approver: isValidApproverInfo ? approverInfo : undefined,
      lcType: typeOfLc,
      // issuanceDate: inssuanceDate,
      expiryDate: expiryDate,
      expiryPlace: placeOfExpiry,
      currency: currency,
      amount: ammount,
      applicantName: nameOfApplicant,
      applicantAddress: applicantAddress,
      beneficiaryName: beneficiaryName,
      beneficiaryAddress: beneficiaryLocation,
      purchaseOrderNumber: purchaseOrderNumber,
      purchaseOrderDate: purchaseOrderDate,
      beneficiaryBankName: beneficiaryBankName,
      beneficiaryBankAddress: beneficiaryBankAddress,
      beneficiaryBankSwiftCode: beneficiaryBankSwiftCode,
      shipmentMode: modeOfShipment,
      toleranceQuantity: quantityTolerance,
      toleranceValue: valueTolerance,
      draftsAt: draftsAt,
      dispatchFrom: dispatchFrom,
      lastShipmentDate: dispatchDate,
      goodsDescription: descriptionOfGood,
      hsnCode: hsnCode,
      IBANcode: ibanCode !== undefined && ibanCode !== null && ibanCode !== "" ? ibanCode : undefined,
      lcDraftAttachement: JSON.stringify(LcDraft),
      purchaseOrderAttachment: JSON.stringify(purchaseOrder),
      performaInvoiceAttachment: JSON.stringify(proformaInvoice),
      creditReportAttachment: JSON.stringify(creditReport),
      requestorComment: requesterDetailComments !== undefined && requesterDetailComments !== null && requesterDetailComments !== "" ? requesterDetailComments : undefined,
      approverComment: approverDetailsComment !== undefined && approverDetailsComment !== null && approverDetailsComment !== "" ? approverDetailsComment : undefined,
      letterOfCreditComment: letterOfCreditDetailComment !== undefined && letterOfCreditDetailComment !== null && letterOfCreditDetailComment !== "" ? letterOfCreditDetailComment : undefined,
      bankChargesComments: bankChargesComment !== undefined && bankChargesComment !== null && bankChargesComment !== "" ? bankChargesComment : undefined,
      attachmentsComment: attachmentComment !== undefined && attachmentComment !== null && attachmentComment !== "" ? attachmentComment : undefined,
      lineOfService: division,
      type: "submit"

    }
    // if (approverInfo !== undefined || approverInfo !== "" || approverInfo !== null) {
    //   payload.approver = approverInfo
    // }
    await mainStore.createLcInssuance(payload, (response) => {
      if (response?.data?.meta?.status === "success") {
        notify(response?.data?.meta?.message);
        navigate("/requestor-journey-dashboard")
        setIsLoading(false)

      } else {
        notify(response?.data?.meta?.message, "danger");
        setIsLoading(false)

      }
    },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false)

      }
    );
  };

  const saveDetails = async () => {


    if (typeOfLc === "FLC  " && creditReport.length === 0) {
      return notify("Please upload the credit report attachment", "danger")
    }

    // if (proformaInvoice.length === 0) {
    //   return notify("Please upload the Proforma Invoice attachment", "danger")
    // }
    // if (purchaseOrder.length === 0) {
    //   return notify("Please upload the credit Purchase Order attachment", "danger")
    // }
    // if (LcDraft.length === 0) {
    //   return notify("Please upload the LcDraft attachment", "danger")
    // }


    setIsLoading(true)
    // if (
    //   requesterMail === "undefined" ||
    //   requesterMail === "" ||
    //   requesterMail === "null" ||
    //   !(isEmail(requesterMail) && requesterMail.toLowerCase().endsWith('@sael.co'))
    // ) {
    //   notify("Requester Mail should end with '@sael.co'", "danger");
    //   return setIsLoading(false)
    // }


    let payload = {
      requesterName: requestName !== undefined && requestName !== null && requestName !== "" ? requestName : undefined,
      requesterEmail: requesterMail !== undefined && requesterMail !== null && requesterMail !== "" ? requesterMail : undefined,
      entity: entity !== undefined && entity !== null && entity !== "" ? entity : undefined,
      unit: requestUnit !== undefined && requestUnit !== null && requestUnit !== "" ? requestUnit : undefined,
      // approver: approverInfo !== undefined && approverInfo !== null && approverInfo !== "" ? approverInfo : undefined,
      approver: isValidApproverInfo ? approverInfo : undefined,
      lcType: typeOfLc !== undefined && typeOfLc !== null && typeOfLc !== "" ? typeOfLc : undefined,
      issuanceDate: inssuanceDate !== undefined && inssuanceDate !== null && inssuanceDate !== "" ? inssuanceDate : undefined,
      expiryDate: expiryDate !== undefined && expiryDate !== null && expiryDate !== "" ? expiryDate : undefined,
      expiryPlace: placeOfExpiry !== undefined && placeOfExpiry !== null && placeOfExpiry !== "" ? placeOfExpiry : undefined,
      currency: currency !== undefined && currency !== null && currency !== "" ? currency : undefined,
      amount: ammount !== undefined && ammount !== null && ammount !== "" ? ammount : undefined,
      applicantName: nameOfApplicant !== undefined && nameOfApplicant !== null && nameOfApplicant !== "" ? nameOfApplicant : undefined,
      applicantAddress: applicantAddress !== undefined && applicantAddress !== null && applicantAddress !== "" ? applicantAddress : undefined,
      beneficiaryName: beneficiaryName !== undefined && beneficiaryName !== null && beneficiaryName !== "" ? beneficiaryName : undefined,
      beneficiaryAddress: beneficiaryLocation !== undefined && beneficiaryLocation !== null && beneficiaryLocation !== "" ? beneficiaryLocation : undefined,
      purchaseOrderNumber: purchaseOrderNumber !== undefined && purchaseOrderNumber !== null && purchaseOrderNumber !== "" ? purchaseOrderNumber : undefined,
      purchaseOrderDate: purchaseOrderDate !== undefined && purchaseOrderDate !== null && purchaseOrderDate !== "" ? purchaseOrderDate : undefined,
      beneficiaryBankName: beneficiaryBankName !== undefined && beneficiaryBankName !== null && beneficiaryBankName !== "" ? beneficiaryBankName : undefined,
      beneficiaryBankAddress: beneficiaryBankAddress !== undefined && beneficiaryBankAddress !== null && beneficiaryBankAddress !== "" ? beneficiaryBankAddress : undefined,
      beneficiaryBankSwiftCode: beneficiaryBankSwiftCode !== undefined && beneficiaryBankSwiftCode !== null && beneficiaryBankSwiftCode !== "" ? beneficiaryBankSwiftCode : undefined,
      shipmentMode: modeOfShipment !== undefined && modeOfShipment !== null && modeOfShipment !== "" ? modeOfShipment : undefined,
      toleranceQuantity: quantityTolerance !== undefined && quantityTolerance !== null && quantityTolerance !== "" ? quantityTolerance : undefined,
      toleranceValue: valueTolerance !== undefined && valueTolerance !== null && valueTolerance !== "" ? valueTolerance : undefined,
      draftsAt: draftsAt !== undefined && draftsAt !== null && draftsAt !== "" ? draftsAt : undefined,
      dispatchFrom: dispatchFrom !== undefined && dispatchFrom !== null && dispatchFrom !== "" ? dispatchFrom : undefined,
      lastShipmentDate: dispatchDate !== undefined && dispatchDate !== null && dispatchDate !== "" ? dispatchDate : undefined,
      goodsDescription: descriptionOfGood !== undefined && descriptionOfGood !== null && descriptionOfGood !== "" ? descriptionOfGood : undefined,
      hsnCode: hsnCode !== undefined && hsnCode !== null && hsnCode !== "" ? hsnCode : undefined,
      IBANcode: ibanCode !== undefined && ibanCode !== null && ibanCode !== "" ? ibanCode : undefined,
      lcDraftAttachement: JSON.stringify(LcDraft),
      purchaseOrderAttachment: JSON.stringify(purchaseOrder),
      performaInvoiceAttachment: JSON.stringify(proformaInvoice),
      creditReportAttachment: JSON.stringify(creditReport),
      requestorComment: requesterDetailComments !== undefined && requesterDetailComments !== null && requesterDetailComments !== "" ? requesterDetailComments : undefined,
      approverComment: approverDetailsComment !== undefined && approverDetailsComment !== null && approverDetailsComment !== "" ? approverDetailsComment : undefined,
      letterOfCreditComment: letterOfCreditDetailComment !== undefined && letterOfCreditDetailComment !== null && letterOfCreditDetailComment !== "" ? letterOfCreditDetailComment : undefined,
      bankChargesComments: bankChargesComment !== undefined && bankChargesComment !== null && bankChargesComment !== "" ? bankChargesComment : undefined,
      attachmentsComment: attachmentComment !== undefined && attachmentComment !== null && attachmentComment !== "" ? attachmentComment : undefined,
      lineOfService: division,
      type: "save"
    };



    await mainStore.createLcInssuance(payload, (response) => {
      if (response?.data?.meta?.status === "success") {
        navigate("/requestor-journey-dashboard")
        notify(response?.data?.meta?.message);
        setIsLoading(false)

      } else {
        notify(response?.data?.meta?.message, "danger");
        setIsLoading(false)
      }
    },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false)
      }
    );
  }


  const handleCreditReport = async (e) => {
    setIsLoading(true)
    const files = e.target.files;
    let formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
      // formData.append('filepath', 'LcRequest');
      let params = 'LcRequest'
      await mainStore.uploadfile(params, formData, (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message);
          setCreditReport(prevState => [...prevState, response.data.data]);
          setIsLoading(false)
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false)
        }
      },
        (error) => {
          notify(error?.response?.data?.meta?.message, "danger");
          setIsLoading(false)
        });
      formData.delete('file');
      formData.delete('filepath');
    }
  }

  const handleProforma = async (e) => {
    setIsLoading(true)
    const files = e.target.files;
    let formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
      // formData.append('filepath', 'LcRequest');
      let params = 'LcRequest'
      await mainStore.uploadfile(params, formData, (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message);
          setProformaInvoice(prevState => [...prevState, response.data.data]);
          setIsLoading(false)
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false)
        }
      },
        (error) => {
          notify(error?.response?.data?.meta?.message, "danger");
          setIsLoading(false)
        });
      formData.delete('file');
      formData.delete('filepath');
    }
  }


  const handlePurchaseOrder = async (e) => {
    setIsLoading(true)
    const files = e.target.files;
    let formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
      // formData.append('filepath', 'LcRequest');
      let params = 'LcRequest'
      await mainStore.uploadfile(params, formData, (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message);
          setPurchaseOrder(prevState => [...prevState, response.data.data]);
          setIsLoading(false)
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false)
        }
      },
        (error) => {
          notify(error?.response?.data?.meta?.message, "danger");
          setIsLoading(false)
        });
      formData.delete('file');
      formData.delete('filepath');
    }
  }


  const handleLcDraft = async (e) => {
    setIsLoading(true)
    const files = e.target.files;
    let formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
      // formData.append('filepath', 'LcRequest');
      let params = 'LcRequest'
      await mainStore.uploadfile(params, formData, (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message);
          setLcDraft(prevState => [...prevState, response.data.data]);
          setIsLoading(false)
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false)
        }
      },
        (error) => {
          notify(error?.response?.data?.meta?.message, "danger");
          setIsLoading(false)
        });
      formData.delete('file');
      formData.delete('filepath');
    }
  }

  const handleCreditReportdownload = async (file) => {
    let payload = {
      filename: file
    }
    callDownloadApi(payload)
  }
  const handleProformaDownload = async (file) => {
    let payload = {
      filename: file
    }
    callDownloadApi(payload)
  }
  const handlePurchaseOrderDowmload = async (file) => {
    let payload = {
      filename: file
    }
    callDownloadApi(payload)
  }
  const handleLcDraftDownload = async (file) => {
    let payload = {
      filename: file
    }
    callDownloadApi(payload)
  }

  const callDownloadApi = async (payload) => {
    setIsLoading(true);
    await mainStore.downloadImage(payload, (response) => {
      if (response?.data?.meta?.status === "success") {
        notify(response?.data?.meta?.message);
        setIsLoading(false);
        const base64File = response?.data?.data?.base64File;
        const mimeType = response?.data?.data?.mimeType;
        const fileNameParts = response?.data?.data?.filename?.split("-");
        const fileName = fileNameParts.slice(1).join("-");
        if (base64File && mimeType) {
          const downloadLink = document.createElement('a');
          downloadLink.href = `data:${mimeType};base64,${base64File}`;
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } else {
          notify("Download link or File type not found", "danger");
        }
      } else {
        notify(response?.data?.meta?.message, "danger");
        setIsLoading(false);
      }
    },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false);
      });
  };

  const addDaysToDate = (dateString, days) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + days);
    return date.toISOString()?.split('T')[0];
  };

  const handleDispatchDateChange = (e) => {
    const selectedDate = e.target.value;
    setDispatchDate(selectedDate);
    const presentationDate = addDaysToDate(selectedDate, 21);
    setExpiryDate(presentationDate);
  };

  useEffect(() =>{
    fetchLos()
  },[])

  const fetchLos = async() =>{
    await mainStore.getLos((status , res) =>{
      if (res?.meta?.status === "success") {
        setLineOfService(res?.data?.allLos);
      } else {
        notify(res?.meta?.message, "danger")
      }
    },
      (error) => {
        notify(error?.response?.meta?.message, "danger")
      }
    );
  }


  return (
    <div className="main-container">
      {isLoading && <Loader />}
      <div className="right-view">
        <Header title={"LC > Issuance"} />
        <div className="row">
          <div className="col-md-12">
            <CustomCard
              cardtitle={"Requester"}
              cardboldtitle={"Details"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput
                      label="Request Number"
                      value={requestNumber}
                      onChange={(e) => setRequestNumber(e.target.value)}
                      placeholder="Request Number"
                      readOnly={true}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Requester Name"
                      value={requestName}
                      readOnly={true}
                      placeholder="Requester Name"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Requester Mail ID"
                      value={requesterMail}
                      readOnly={true}
                      placeholder="Requester mail ID"
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="custominput-container">
                      <div className="custom-input">
                        <label htmlFor="" >Entity </label>
                        <div style={{ position: 'relative' }}>
                          <select
                            className="form-control"
                            value={requestEntity.name}
                            onChange={(e) => { setEntity(e.target.value) }}
                          >
                            <option value="">Select Entity</option>
                            {requestEntity.map((entity, index) => (
                              <option key={index} value={entity.name}>
                                {entity.name}
                              </option>
                            ))}
                          </select>
                          <i className="fas fa-chevron-down approver-drop-down"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Unit"
                      value={requestUnit}
                      onChange={(e) => setRequestUnit(e.target.value)}
                      placeholder="Unit"
                    />
                  </div>

                  <div className="col-md-3">
                    <div className="custominput-container">
                      <div className="custom-input">
                        <label htmlFor="" >Line Of Service  <span style={{ color: 'red' }}>*</span></label>
                        <div style={{ position: 'relative' }}>
                          <select
                            className="form-control"
                            value={division}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Line Of Service </option>
                            {lineOfService.map((item, index) => (
                              <option key={index} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <i className="fas fa-chevron-down approver-drop-down"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <CustomInput
                      label="Comment"
                      value={requesterDetailComments}
                      onChange={(e) => setRequesterDetailComments(e.target.value)}
                      placeholder="Comment"
                    />
                  </div>

                </div>
              }
            />
          </div>
          <div className="col-md-12">
            <CustomCard
              cardtitle={"Approver"}
              cardboldtitle={"Details"}
              cardbody={
                <div>
                  {approverInfo.map((approver, index) => (
                    <div key={index} className="row">
                      <div className="col-md-4">
                        <div className="custominput-container">
                          <div className="custom-input">
                            <label htmlFor={`approverName${index}`} >
                              {/* Approver L{index + 1} Name <span style={{ color: 'red' }}>*</span> */}
                              {index === 0 && <>Approver Purchase <span style={{ color: 'red' }}>*</span></>}
                              {index === 1 && <>Approver Finance <span style={{ color: 'red' }}>*</span></>}
                            </label>
                            <div style={{ position: 'relative' }}>
                              <select
                                id={`approverName${index}`}
                                className="form-control"
                                value={approver.approverName}
                                onChange={(e) => {
                                  const selectedApprover = approverList.find(approver => approver.name === e.target.value);
                                  handleApproverChange(index, "approverName", e.target.value);
                                  handleApproverChange(index, "approverId", selectedApprover.id);
                                  handleApproverChange(index, "approverEmail", selectedApprover.email);
                                  handleApproverChange(index, "approverDepartment", selectedApprover.user_department);
                                }}
                              >
                                <option value="">Select Approver</option>
                                {index === 0 && approverList.filter(approver => approver.user_department === "Purchase").map((filteredApprover, filteredIndex) => (
                                  <option key={filteredIndex} value={filteredApprover.name}>
                                    {filteredApprover.name}
                                  </option>
                                ))}
                                {index === 1 && approverList.filter(approver => approver.user_department === "Finance").map((filteredApprover, filteredIndex) => (
                                  <option key={filteredIndex} value={filteredApprover.name}>
                                    {filteredApprover.name}
                                  </option>
                                ))}
                              </select>
                              <i className="fas fa-chevron-down approver-drop-down"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <CustomInput
                          require={true}
                          readOnly={true}
                          label="Department"
                          value={approver.approverDepartment}
                          onChange={(e) => handleApproverChange(index, "approverDepartment", e.target.value)}
                          placeholder="Department"
                        />
                      </div>
                      <div className="col-md-4">
                        <CustomInput
                          require={true}
                          readOnly={true}
                          label="Mail ID"
                          value={approver.approverEmail}
                          onChange={(e) => handleApproverChange(index, "approverEmail", e.target.value)}
                          placeholder="Mail ID"
                        />
                      </div>
                    </div>
                  ))}
                  <div className="col-md-4">
                    <CustomInput
                      label="Comment"
                      value={approverDetailsComment}
                      onChange={(e) => setApproverDetailsComment(e.target.value)}
                      placeholder="Comment"
                    />
                  </div>
                </div>
              }
            />
          </div>

          <div className="col-md-12">
            <CustomCard
              cardtitle={"Bank "}
              cardboldtitle={"Details"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="Bank Name"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                      placeholder="Bank Name"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="Bank Account Number"
                      value={bankAccountNumber}
                      onChange={(e) => setBankAccountNumber(e.target.value)}
                      placeholder="Bank Account Number"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="Bank GL Code"
                      value={bankGlCode}
                      onChange={(e) => setBankGlCode(e.target.value)}
                      placeholder="Bank GL Code"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="Comment"
                      value={bankDetailComment}
                      onChange={(e) => setBankDetailComment(e.target.value)}
                      placeholder="Comment"
                    />
                  </div>
                </div>
              }
            />
          </div>

          <div className="col-md-12">
            <CustomCard
              cardtitle={"Letter of Credit"}
              cardboldtitle={"Details"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput
                      label="Reference Number"
                      readOnly={true}
                      value={refrenceNumber}
                      onChange={(e) => setRefrenceNumber(e.target.value)}
                      placeholder="Reference Number"
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="custominput-container">
                      <div className="custom-input">
                        <label htmlFor="" >Type of LC <span style={{ color: 'red' }}>*</span></label>
                        <div style={{ position: 'relative' }}>
                          <select
                            className="form-control"
                            value={typeOfLc}
                            onChange={(e) => setTypeOfLc(e.target.value)}
                          >
                            <option value="">Select Type of LC</option>
                            {typeOfLcList.map((item, index) => (
                              <option key={index} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <i className="fas fa-chevron-down approver-drop-down"></i>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="col-md-3">
                    <CustomInput
                      label="Issuance Date"
                      value={inssuanceDate}
                      readOnly={true}
                      onChange={(e) => setInssuanceDate(e.target.value)}
                      placeholder="Issuance Date"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      type="date"
                      label="Date of Shipment/dispatch"
                      min={new Date().toISOString()?.split('T')[0]}
                      value={dispatchDate?.toLocaleString("en-GB")}
                      onChange={handleDispatchDateChange}
                      placeholder="Date of Shipment/dispatch"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      readOnly={true}
                      type="date"
                      label="Expiry Date"
                      value={expiryDate}
                      onChange={(e) => setExpiryDate(e.target.value)}
                      placeholder="Expiry Date"
                      min={new Date().toISOString()?.split('T')[0]}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Place of Expiry"
                      value={placeOfExpiry}
                      onChange={(e) => setPlaceOfExpiry(e.target.value)}
                      placeholder="Place of Expiry"
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="custominput-container">
                      <div className="custom-input">
                        <label htmlFor="" >Currency <span style={{ color: 'red' }}>*</span> </label>
                        <div style={{ position: 'relative' }}>
                          <select
                            className="form-control"
                            value={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                          >
                            <option value="">Select Currency</option>
                            {currencyList.map((item, index) => (
                              <option key={index} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <i className="fas fa-chevron-down approver-drop-down"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Amount"
                      value={ammount}
                      onChange={(e) => setAmmount(e.target.value)}
                      placeholder="Amount"
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="custominput-container">
                      <div className="custom-input">
                        <label htmlFor="" >Name of Applicant <span style={{ color: 'red' }}>*</span> </label>
                        <div style={{ position: 'relative' }}>
                          <select
                            className="form-control"
                            value={nameOfApplicant}
                            onChange={(e) => { setNameOfApplicant(e.target.value) }}
                          >
                            <option value="">Select Name of Applicant</option>
                            {requestEntity.map((entity, index) => (
                              <option key={index} value={entity.name}>
                                {entity.name}
                              </option>
                            ))}
                          </select>
                          <i className="fas fa-chevron-down approver-drop-down"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Address of Applicant"
                      value={applicantAddress}
                      onChange={(e) => setApplicantAddress(e.target.value)}
                      placeholder="Address of Applicant"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Name of Beneficiary"
                      value={beneficiaryName}
                      onChange={(e) => setBeneficiaryName(e.target.value)}
                      placeholder="Name of Beneficiary"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Address of Beneficiary"
                      value={beneficiaryLocation}
                      onChange={(e) => setBeneficiaryLocation(e.target.value)}
                      placeholder="Address of Beneficiary"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Purchase Order No."
                      value={purchaseOrderNumber}
                      onChange={(e) => setPurchaseOrderNumber(e.target.value)}
                      placeholder="Purchase Order No."
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Purchase Order Date"
                      type="date"
                      value={purchaseOrderDate?.toLocaleString("en-GB")}
                      onChange={(e) => setPurchaseOrderDate(e.target.value)}
                      placeholder="Purchase Order Date"
                      // min={new Date().toISOString()?.split("T")[0]}
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Beneficiary Bank Name"
                      value={beneficiaryBankName}
                      onChange={(e) => setBeneficiaryBankName(e.target.value)}
                      placeholder="Beneficiary Bank Name"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Beneficiary Bank Address"
                      value={beneficiaryBankAddress}
                      onChange={(e) => setBeneficiaryBankAddress(e.target.value)}
                      placeholder="Beneficiary Bank Address"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Beneficiary Bank Swift Code"
                      value={beneficiaryBankSwiftCode}
                      onChange={(e) => setBeneficiaryBankSwiftCode(e.target.value)}
                      placeholder="Beneficiary Bank Swift Code"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Mode of Shipment"
                      value={modeOfShipment}
                      onChange={(e) => setModeOfShipment(e.target.value)}
                      placeholder="Mode of Shipment"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Quantity Tolerance"
                      value={quantityTolerance}
                      onChange={(e) => setQuantityTolerance(e.target.value)}
                      placeholder="Quantity Tolerance"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Value Tolerance"
                      value={valueTolerance}
                      onChange={(e) => setValueTolerance(e.target.value)}
                      placeholder="Value Tolerance"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Drafts At"
                      value={draftsAt}
                      onChange={(e) => setDraftAt(e.target.value)}
                      placeholder="Drafts At"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Dispatch From"
                      value={dispatchFrom}
                      onChange={(e) => setDispatchFrom(e.target.value)}
                      placeholder="Dispatch From"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Description of Goods"
                      value={descriptionOfGood}
                      onChange={(e) => setDescriptionOfGood(e.target.value)}
                      placeholder="Description of Goods"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="HSN Code"
                      value={hsnCode}
                      onChange={(e) => setHsnCode(e.target.value)}
                      placeholder="HSN Code"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                     require={true}
                      label="IBAN Code"
                      value={ibanCode}
                      onChange={(e) => setIbanCode(e.target.value)}
                      placeholder="IBAN Code"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Comment"
                      value={letterOfCreditDetailComment}
                      onChange={(e) => setLetterOfCreditDetailComment(e.target.value)}
                      placeholder="Comment"
                    />
                  </div>
                </div>
              }
            />
          </div>

          <div className="col-md-12">
            <CustomCard
              cardtitle={"Bank Charges"}
              cardboldtitle={"Details"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput
                      label="LC Charges"
                      readOnly={true}
                      value={bgCharges}
                      onChange={(e) => setBgCharges(e.target.value)}
                      placeholder="BG Charges"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="GST"
                      value={gst}
                      readOnly={true}
                      onChange={(e) => setGst(e.target.value)}
                      placeholder="GST"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="Bank Charges GL Code"
                      value={bgChargesGlCode}
                      onChange={(e) => setBgChargesGlCode(e.target.value)}
                      placeholder="Bank Charges GL Code"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="GST GL Code"
                      value={gstGlCode}
                      onChange={(e) => setGstGlCode(e.target.value)}
                      placeholder="GST GL Code"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Comment"
                      value={bankChargesComment}
                      onChange={(e) => setBankChargesComment(e.target.value)}
                      placeholder="Comment"
                    />
                  </div>
                </div>
              }
            />
          </div>

          <div className="col-md-12">
            <CustomCard
              cardboldtitle={"Attachments"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput
                      label="LC Draft"
                      require={true}
                      onChange={handleLcDraft}
                      type="file"
                      placeholder="Browse File"
                    />
                    <div className="uploaded-images">
                      {LcDraft.map((item, index) => (
                        <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: '5px' }} onClick={() => handleLcDraftDownload(item)} className="url-download">
                          {item}
                        </div>
                      ))}
                    </div>                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Purchase Order"
                      onChange={handlePurchaseOrder}
                      type="file"
                      placeholder="Browse File"
                    />
                    <div className="uploaded-images">
                      {purchaseOrder.map((item, index) => (
                        <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: '5px' }} onClick={() => handlePurchaseOrderDowmload(item)} className="url-download">
                          {item}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Proforma Invoice"
                      placeholder="Browse File"
                      type="file"
                      require={true}
                      onChange={handleProforma}
                    />
                    <div className="uploaded-images">
                      {proformaInvoice.map((item, index) => (
                        <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: '5px' }} onClick={() => handleProformaDownload(item)} className="url-download">
                          {item}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={selectedTypeOfLC ? false : true}
                      label="Credit Report"
                      type="file"
                      onChange={handleCreditReport}
                      placeholder="Browse File"
                    />
                    <div className="uploaded-images">
                      {creditReport.map((item, index) => (
                        <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: '5px' }} onClick={() => handleCreditReportdownload(item)} className="url-download">
                          {item}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="col-md-3">
                    <CustomInput
                      label="Comment"
                      value={attachmentComment}
                      onChange={(e) => setAttachmentComment(e.target.value)}
                      placeholder="Comment"
                    />
                  </div>
                </div>
              }
            />
          </div>

         {Viewer !=="Viewer" && <div className="col-md-12 text-end">
            <CustomButton style={"btn--outline me-3"} children={"Save"} onClick={saveDetails} />
            <CustomButton
              children={"Submit for approval"}
              onClick={submitForApproval}
            />
          </div>}
        </div>
      </div>
    </div>
  );
};

export default RJLCIssuance;
