
import { makeAutoObservable } from "mobx";
import Service from "../shared/service/service";



class mainStore {
    constructor(rootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    } 

    async changePassword(formData, callback, callbackerror) {
        await Service.post("changePassword", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }


    async getInstruments(formData ,callback, callbackerror) {
        await Service.get("instruments", formData,async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getInstrumentstype(id,formData, callback, callbackerror) {
        await Service.get(`requestType/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async createRequestorBgInssuance(formData, callback, callbackerror) {
        await Service.post("bgrequest", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async getRequestorBgInssuance(formData, callback, callbackerror) {
        await Service.post("all_bgrequest", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async editRequestorBgInssuance(id ,formData, callback, callbackerror) {
        await Service.post(`bgrequest/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getDashboardData(formData, callback, callbackerror) {
        await Service.post("userDashboard", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async getEntity(formData, callback, callbackerror) {
        await Service.getApi("entities", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async approveBgInssuance(id ,formData, callback, callbackerror) {
        await Service.post(`approveBGRequest/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async createRequestorBgAmendmet(id ,formData, callback, callbackerror) {
        await Service.post(`bgammendment/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async createRequestorBgCancellation(id ,formData, callback, callbackerror) {
        await Service.post(`bgcancellation/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async allBgAmendment(formData, callback, callbackerror) {
        await Service.post(`all_bgammendment`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async allBgCancellation(formData, callback, callbackerror) {
        await Service.post("all_bgcancellation", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async closeBgRequest(id, formData,callback, callbackerror) {
        await Service.post(`closeBGRequest/${id}`,formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }


    async updateBgRequestTreasury(id,formData, callback, callbackerror) {
        await Service.post(`bg_treasurer/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getBgAudittrail(id,formData, callback, callbackerror) {
        await Service.post(`audit_trail/${id}`,{}, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getBgRequestById(id,formData, callback, callbackerror) {
        await Service.getApi(`bgrequest/${id}`,formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getApprovers(formData , callback, callbackerror){
        await Service.post(`approvers` , formData, async(response)=>{
            return callback(response)
        }).catch((error)=>{
            return callbackerror(error)
        })
    }

    async uploadfile(params,formData, callback, callbackerror) {
        await Service.postUpload(`uploadFile/${params}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async downloadImage(formData, callback, callbackerror) {
        await Service.post("downloadFile", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    
    async createLcInssuance(formData, callback, callbackerror) {
        await Service.post("lcrequest", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async getLcInssuance(formData, callback, callbackerror) {
        await Service.post("all_lcrequest", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async editLcInssuance(id ,formData, callback, callbackerror) {
        await Service.post(`lcrequest/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async approveLCInssuance(id ,formData, callback, callbackerror) {
        await Service.post(`approveLCRequest/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async updateLcRequestTreasury(id,formData, callback, callbackerror) {
        await Service.post(`lc_treasurer/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async closeLcRequest(id, formData,callback, callbackerror) {
        await Service.post(`closeLCRequest/${id}`,formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getAuditLcDetail(id,formData, callback, callbackerror) {
        await Service.post(`audit_trail/${id}`,{}, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async createLcAmendmet(id ,formData, callback, callbackerror) {
        await Service.post(`lcammendment/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async createLcCancellation(id ,formData, callback, callbackerror) {
        await Service.post(`lccancellation/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async sendMassege(formData , callback, callbackerror){
        await Service.post(`chat` , formData, async(response)=>{
            return callback(response)
        }).catch((error)=>{
            return callbackerror(error)
        })
    }

    async getChatResponse(id,formData, callback, callbackerror) {
        await Service.getApi(`chat/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async bgListingData(formData, callback, callbackerror) {
        await Service.post("toExpireBgRequest", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async LcListingData(formData, callback, callbackerror) {
        await Service.post("toExpireLcRequest", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getBankDetails(id,formData, callback, callbackerror) {
        await Service.getApi(`banks/${id}`, formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getBankAccountDetails(formData, callback, callbackerror) {
        await Service.post("bankAccountList", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }
    async getBankGlCode(formData, callback, callbackerror) {
        await Service.post("bankGlCodes", formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

    async getLos(formData, callback, callbackerror) {
        await Service.getApi(`lineOfServices`,formData, async (response) => {
            return callback(response)
        }).catch((error) => {
            return callbackerror(error);
        })
    }

}
 

export default mainStore;
