import React, { useState, useEffect } from "react";
import Header from "../Header";
import CustomCard from "../CustomeCard";
import CustomInput from "../CustomInput";
import CustomButton from "../Button/Button";
import notify from "../../shared/notify";
import { useStores } from "../../stores";
import { useLocation, useNavigate } from "react-router-dom"
import TreasuryCloseLcModal from "../Modal/TreasuryCloseLcModal"
import ReactExport from "react-export-excel";
import Loader from "../helper/Loader/loader";
import FormatDate from "../helper/formatDate/formateDate";
import editImg from "../../assets/images/edit.png"



const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const TreasuryLcIssue = () => {
    const { mainStore } = useStores()
    const navigate = useNavigate()
    const { state } = useLocation();
    const [isLoading, setIsLoading] = useState(false)
    const userName = sessionStorage.getItem("name")
    const roleName = sessionStorage.getItem("roleName")
    const [chatComment, setChatComment] = useState("")
    const [chat, setChat] = useState([])
    const [startChat, setStartChat] = useState()
    const [requestNumber, setRequestNumber] = useState(state?.item?.requestNumber);
    const [requestName, setRequestName] = useState(state?.item?.requesterName);
    const [requesterMail, setRequesterMail] = useState(state?.item?.requesterEmail);
    const [requestEntity, setRequestEntity] = useState(state?.item?.entity);
    const [requestUnit, setRequestUnit] = useState(state?.item?.unit);
    const [approverInfo, setApproverInfo] = useState([
        { approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L1", approverId: "" },
        { approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L2", approverId: "" }
    ]);
    const [bankName, setBankName] = useState(state?.item?.bankName);
    const [bankAccountNumber, setBankAccountNumber] = useState(state?.item?.bankAccountNumber);
    const [bankGlCode, setBankGlCode] = useState(state?.item?.bankGlCode);
    const [bankGlCodeData, setBankGlCodeData] = useState([])
    const [bankAccountData, setBankAccountData] = useState([])
    const [bankNameData, setBankNameData] = useState([])
    const [entityId, setEntityId] = useState("")
    const [refrenceNumber, setRefrenceNumber] = useState(state?.item?.referenceNumber);
    const [typeOfLc, setTypeOfLc] = useState(state?.item?.lcType);
    const [beneficiaryName, setBeneficiaryName] = useState(state?.item?.beneficiaryName);
    const [beneficiaryLocation, setBeneficiaryLocation] = useState(state?.item?.beneficiaryAddress);
    const [inssuanceDate, setInssuanceDate] = useState(state?.item?.issuanceDate);
    const [expiryDate, setExpiryDate] = useState(state?.item?.expiryDate);
    const [placeOfExpiry, setPlaceOfExpiry] = useState(state?.item?.expiryPlace)
    const [currency, setCurrency] = useState(state?.item?.currency);
    const [ammount, setAmmount] = useState(state?.item?.amount);
    const [nameOfApplicant, setNameOfApplicant] = useState(state?.item?.applicantName);
    const [applicantAddress, setApplicantAddress] = useState(state?.item?.applicantAddress);
    const [bgCharges, setBgCharges] = useState(state?.item?.lcCharge);
    const [gst, setGst] = useState(state?.item?.gst);
    const [bgChargesGlCode, setBgChargesGlCode] = useState(state?.item?.bankChargesGlCode);
    const [gstGlCode, setGstGlCode] = useState(state?.item?.gstGlCode);
    const [purchaseOrderNumber, setPurchaseOrderNumber] = useState(state?.item?.purchaseOrderNumber)
    const [purchaseOrderDate, setPurchaseOrderDate] = useState(state?.item?.purchaseOrderDate)
    const [beneficiaryBankName, setBeneficiaryBankName] = useState(state?.item?.beneficiaryBankName)
    const [beneficiaryBankAddress, setBeneficiaryBankAddress] = useState(state?.item?.beneficiaryBankAddress)
    const [beneficiaryBankSwiftCode, setBeneficiaryBankSwiftCode] = useState(state?.item?.beneficiaryBankSwiftCode)
    const [modeOfShipment, setModeOfShipment] = useState(state?.item?.shipmentMode)
    const [quantityTolerance, setQuantityTolerance] = useState(state?.item?.toleranceQuantity)
    const [valueTolerance, setValueTolerance] = useState(state?.item?.toleranceValue)
    const [draftsAt, setDraftsAt] = useState(state?.item?.draftsAt)
    const [dispatchFrom, setDispatchFrom] = useState(state?.item?.dispatchFrom)
    const [hsnCode, setHsnCode] = useState(state?.item?.hsnCode)
    const [dispatchDate, setDispatchDate] = useState(state?.item?.lastShipmentDate)
    const [descriptionOfGood, setDescriptionOfGood] = useState(state?.item?.goodsDescription)
    const [ibanCode, setIbanCode] = useState(state?.item?.IBANcode)
    const [requesterDetailComments, setRequesterDetailComment] = useState(state?.item?.requestorComment)
    const [approverDetailsComment, setApproverDetailsComment] = useState(state?.item?.approverComment)
    const [bankDetailComment, setBankDetailComment] = useState(state?.item?.bankComment)
    const [letterOfCreditDetailComment, setLetterOfCreditDetailComment] = useState(state?.item?.letterOfCreditComment)
    const [bankChargesComment, setBankChargesComment] = useState(state?.item?.bankChargesComments)
    const [attachmentComment, setAttachmentComment] = useState(state?.item?.attachmentsComment)
    const [editTreasuryDetails, setEditTreasuryDetails] = useState()



    const [LcDraft, setLcDraft] = useState(() => {
        const item = state?.item?.lcDraftAttachement;
        if (item) {
            try {
                return JSON.parse(item);
            } catch (error) {
                console.error("Error parsing bgText JSON:", error);
            }
        }
        return [];
    });
    const [purchaseOrder, setPurchaseOrder] = useState(() => {
        const item = state?.item?.purchaseOrderAttachment;
        if (item) {
            try {
                return JSON.parse(item);
            } catch (error) {
                console.error("Error parsing bgText JSON:", error);
            }
        }
        return [];
    });
    const [proformaInvoice, setProformaInvoice] = useState(() => {
        const item = state?.item?.performaInvoiceAttachment;
        if (item) {
            try {
                return JSON.parse(item);
            } catch (error) {
                console.error("Error parsing bgText JSON:", error);
            }
        }
        return [];
    });
    const [creditReport, setCreditReport] = useState(() => {
        const item = state?.item?.creditReportAttachment;
        if (item) {
            try {
                return JSON.parse(item);
            } catch (error) {
                console.error("Error parsing bgText JSON:", error);
            }
        }
        return [];
    });

    const [showtresuryCloseModal, setShowTresuryCloseModal] = useState(false)
    const [getAuditDetail, setGetAuditDetail] = useState([])
    const [approverList, setApproverList] = useState([]);
    const [cancellations, setCancellations] = useState([]);
    const [amendments, setAmendments] = useState([]);

    const [division, setDivision] = useState(state?.item?.lineOfService);
    const [entity, setEntity] = useState([]);
    const [lineOfService, setLineOfService] = useState([])

  
    const fetchLos = async () => {
      await mainStore.getLos((status, res) => {
        if (res?.meta?.status === "success") {
          setLineOfService(res?.data?.allLos);
        } else {
          notify(res?.meta?.message, "danger")
        }
      },
        (error) => {
          notify(error?.response?.meta?.message, "danger")
        }
      );
    }

    useEffect(() => {
        getLcAuditDetail()
        fetchChatResponse()
        fetchEntity()
        fetchLos()
    }, [])



    const fetchEntity = async (e) => {
        await mainStore.getEntity((status, response) => {
            if (response?.meta?.status === "success") {
                setEntity(response?.data?.entitites);
                setBankAccountData([])
                setBankGlCodeData([])
                if (e) {
                    const filterEntityId = response?.data?.entitites?.filter(item => item.name === e)
                    setEntityId(filterEntityId[0]?.entity_code)
                    fetchBankNameData(filterEntityId[0]?.entity_code)
                } else if (e === "") {
                    fetchBankNameData(undefined)
                } else {
                    const filterBankId = response?.data?.entitites?.filter(item => item.name === requestEntity)
                    setEntityId(filterBankId[0]?.entity_code)
                    fetchBankNameData(filterBankId[0]?.entity_code)
                }
            } else {
                notify(response?.meta?.message, "danger");
            }
        },
            (error) => {
                notify(error?.response?.meta?.message, "danger");
            }
        );
    };

    const toggleChatButton = () => {
        setStartChat(!startChat)
    }
    const fetchApproverDetail = async (lineOfService) => {
        if (!lineOfService) {
            return;
        }

        let payload = {
            lineOfService,
        };
        await mainStore.getApprovers(payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                setApproverList(response?.data?.data?.users);
            } else {
                notify(response?.data?.meta?.message, "danger");
            }
        },
            (error) => {
                notify(error?.response?.data?.meta?.message, "danger");
            }
        );
    };
    const handleInputChange = (e) => {
        let value = e.target.value;
        setDivision(value);
        fetchApproverDetail(value);
        setApproverInfo([
            { approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L1", approverId: "" },
            { approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L2", approverId: "" }
        ]);
    };
    useEffect(() => {
        if (division) {
            fetchApproverDetail(division);
        }
    }, []);

    const handleSendMassege = async () => {
        setIsLoading(true)
        let payload = {
            requestNumber: state?.item?.requestNumber,
            userName: userName,
            userRole: roleName,
            message: chatComment
        }
        await mainStore.sendMassege(payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                notify(response?.data?.meta?.message)
                fetchChatResponse()
                setChatComment("")
                setIsLoading(false)
            } else {
                notify(response?.data?.meta?.message, "danger")
                setIsLoading(false)
                setChatComment("")
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setChatComment("")
        })
    }

    const fetchChatResponse = async () => {
        mainStore.getChatResponse(state?.item?.requestNumber, (status, response) => {
       
            if (response?.meta?.status === "success") {
                setChat(response?.data?.chats)
            } else {
                notify(response?.meta?.message, "danger")
            }
        }, (error) => {
            notify(error?.response?.meta?.message, "danger")
        })
    }

    useEffect(() => {
        if (state?.item?.approvers?.length > 0) {
            let arr = [];
            state.item.approvers.map((item) => {
                let obj = {
                    approverId: item?.approver_id,
                    approverName: item?.user?.name,
                    approverEmail: item?.user?.email,
                    approverDepartment: item?.department,
                    approverLevel: item?.approver_level
                }
                arr.push(obj)
            })
            setApproverInfo(arr);
        }
    }, [])


    const handleApproverChange = (index, field, value) => {
        const updatedApproverInfo = [...approverInfo];
        updatedApproverInfo[index][field] = value;
        setApproverInfo(updatedApproverInfo);
    };


    const handleCreditReportdownload = async (item) => {
        let payload = {
            filename: item
        }
        callDownloadApi(payload)
    }
    const handleProformaDownload = async (item) => {
        let payload = {
            filename: item
        }
        callDownloadApi(payload)
    }
    const handlePurchaseOrderDownload = async (item) => {
        let payload = {
            filename: item
        }
        callDownloadApi(payload)
    }
    const handleLcDraftDownload = async (item) => {
        let payload = {
            filename: item
        }
        callDownloadApi(payload)
    }


    const callDownloadApi = async (payload) => {
        setIsLoading(true);
        await mainStore.downloadImage(payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                notify(response?.data?.meta?.message);
                setIsLoading(false);
                const base64File = response?.data?.data?.base64File;
                const mimeType = response?.data?.data?.mimeType;
                const fileNameParts = response?.data?.data?.filename?.split("-");
                const fileName = fileNameParts.slice(1).join("-");
                if (base64File && mimeType) {
                    const downloadLink = document.createElement('a');
                    downloadLink.href = `data:${mimeType};base64,${base64File}`;
                    downloadLink.download = fileName;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                } else {
                    notify("Download link or File type not found", "danger");
                }
            } else {
                notify(response?.data?.meta?.message, "danger");
                setIsLoading(false);
            }
        },
            (error) => {
                notify(error?.response?.data?.meta?.message, "danger");
                setIsLoading(false);
            });
    };


    // const handleAmendmentDownload = async (item) => {
    //     let payload = {
    //         filename: item
    //     }
    //     callDownloadApi(payload)
    // }

    const handleCancellationDownload = async (item) => {
        let payload = {
            filename: item
        }
        callDownloadApi(payload)
    }

    const amendmentData = amendments.map(amendment => ({
        ammendmentId: amendment.id,
        ...(amendment?.lcCharge !== undefined && amendment?.lcCharge !== null && amendment?.lcCharge !== "" && { lcCharge: amendment?.lcCharge }),
        ...(amendment?.gst !== undefined && amendment?.gst !== null && amendment?.gst !== "" && { gst: amendment?.gst }),
        ...(amendment?.stampCharges !== undefined && amendment?.stampCharges !== null && amendment?.stampCharges !== "" && {stampCharges: amendment?.stampCharges }),
        ...(amendment?.bankChargesGlCode !== undefined && amendment?.bankChargesGlCode !== null && amendment?.bankChargesGlCode !== "" && {bankChargesGlCode: amendment?.bankChargesGlCode }),
        ...(amendment?.gstGlCode !== undefined && amendment?.gstGlCode !== null && amendment?.gstGlCode !== "" && {gstGlCode: amendment?.gstGlCode }),
        ...(amendment?.comment !== undefined && amendment?.comment !== null && amendment?.comment !== "" && {comment: amendment?.comment }),
        ...(amendment?.clause!== undefined && amendment?.clause!== null && amendment?.clause!== "" && {clause: amendment?.clause}),
        ...(amendment?.others !== undefined && amendment?.others !== null && amendment?.others !== "" && {others: amendment?.others }),
        ...(amendment?.expiryDate !== undefined && amendment?.expiryDate !== null && amendment?.expiryDate !== "" && {expiryDate: amendment?.expiryDate }),
        ...(amendment?.claimExpiryDate !== undefined && amendment?.claimExpiryDate !== null && amendment?.claimExpiryDate !== "" && {claimExpiryDate: amendment?.claimExpiryDate }),
        // lcCharge: amendment.lcCharge,
        // gst: amendment?.gst,
        // stampCharges: amendment?.stampCharges,
        // bankChargesGlCode: amendment?.bankChargesGlCode,
        // gstGlCode: amendment?.gstGlCode,
        attachments: JSON.stringify(amendment.attachments),
        // comment: amendment?.comment,
        // clause: amendment?.clause,
        // others: amendment?.others,
        // expiryDate: amendment?.expiryDate,
        // claimExpiryDate: amendment?.claimExpiryDate
    }));

    const cancellationData = cancellations.map(cancellation => ({
        cancellationId: cancellation.id,
        // lcCharge: cancellation?.lcCharge,
        // gst: cancellation?.gst,
        // stampCharges: cancellation?.stampCharges,
        // bankChargesGlCode: cancellation?.bankChargesGlCode,
        // gstGlCode: cancellation?.gstGlCode,
        // comment: cancellation?.comment,
        attachments: JSON.stringify(cancellation.attachments),
        // cancellationDate: cancellation?.cancellationDate,
        ...(cancellation?.lcCharge !== undefined && cancellation?.lcCharge !== null && cancellation?.lcCharge !== "" && { lcCharge: cancellation?.lcCharge }),
        ...(cancellation?.gst !== undefined && cancellation?.gst !== null && cancellation?.gst !== "" && { gst: cancellation?.gst }),
        ...(cancellation?.stampCharges !== undefined && cancellation?.stampCharges !== null && cancellation?.stampCharges !== "" && {stampCharges: cancellation?.stampCharges }),
        ...(cancellation?.bankChargesGlCode !== undefined && cancellation?.bankChargesGlCode !== null && cancellation?.bankChargesGlCode !== "" && {bankChargesGlCode: cancellation?.bankChargesGlCode }),
        ...(cancellation?.gstGlCode !== undefined && cancellation?.gstGlCode !== null && cancellation?.gstGlCode !== "" && {gstGlCode: cancellation?.gstGlCode }),
        ...(cancellation?.comment !== undefined && cancellation?.comment !== null && cancellation?.comment !== "" && {comment: cancellation?.comment }),
        ...(cancellation?.cancellationDate !== undefined && cancellation?.cancellationDate !== null && cancellation?.cancellationDate !== "" && {cancellationDate: cancellation?.cancellationDate }),

    }))

    const isValidApproverInfo = approverInfo.every(approver => (
        approver.approverName !== "" &&
        approver.approverDepartment !== "" &&
        approver.approverEmail !== "" &&
        approver.approverId !== ""
    ));

    const UpdateBgRequestByTreasury = async () => {

        if (typeOfLc === "FLC  " && creditReport.length === 0) {
            return notify("Please upload the credit report attachment" ,"danger")
        }

        if(proformaInvoice.length === 0){
            return notify("Please upload the Proforma Invoice attachment" ,"danger") 
        }
        if(purchaseOrder.length === 0){
            return notify("Please upload the credit Purchase Order attachment" ,"danger") 
        }
        if(LcDraft.length === 0){
            return notify("Please upload the LcDraft attachment" ,"danger") 
        }


        setIsLoading(true)
        let payload = {
            referenceNumber: refrenceNumber,
            issuanceDate: inssuanceDate,
            ...(bankName !== undefined && bankName !== null && bankName !== "" && { bankName: bankName }),
            ...(bankAccountNumber !== undefined && bankAccountNumber !== null && bankAccountNumber !== "" && { bankAccountNumber: bankAccountNumber }),
            ...(bankGlCode !== undefined && bankGlCode !== null && bankGlCode !== "" && { bankGlCode: bankGlCode }),
            ...(bgCharges !== undefined && bgCharges !== null && bgCharges !== "" && { lcCharge: bgCharges }),
            ...(gst !== undefined && gst !== null && gst !== "" && { gst: gst }),
            ...(bgChargesGlCode !== undefined && bgChargesGlCode !== null && bgChargesGlCode !== "" && { bankChargesGlCode: bgChargesGlCode }),
            ...(gstGlCode !== undefined && gstGlCode !== null && gstGlCode !== "" && { gstGlCode: gstGlCode }),
            // lcCharge: bgCharges,
            // gst: gst,
            // bankChargesGlCode: bgChargesGlCode,
            // gstGlCode: gstGlCode,
            ammendment: amendmentData,
            cancellation: cancellationData,
            bankComment: bankDetailComment!== undefined && bankDetailComment !== null && bankDetailComment !== "" ? bankDetailComment : undefined,
            lineOfService: division,
            approver: isValidApproverInfo ? approverInfo : undefined,
            ...(requestEntity !== undefined && requestEntity !== null && requestEntity !== "" && { entity: requestEntity }),
            ...(requestUnit !== undefined && requestUnit !== null && requestUnit !== "" && { unit: requestUnit }),
            ...(requesterDetailComments !== undefined && requesterDetailComments !== null && requesterDetailComments !== "" && { requestorComment: requesterDetailComments }),
            ...(approverDetailsComment !== undefined && approverDetailsComment !== null && approverDetailsComment !== "" && { approverComment: approverDetailsComment }),
            ...(letterOfCreditDetailComment !== undefined && letterOfCreditDetailComment !== null && letterOfCreditDetailComment !== "" && { letterOfCreditComment: letterOfCreditDetailComment }),
            ...(bankChargesComment !== undefined && bankChargesComment !== null && bankChargesComment !== "" && { bankChargesComments: bankChargesComment }),
            ...(attachmentComment !== undefined && attachmentComment !== null && attachmentComment !== "" && { attachmentsComment: attachmentComment }),
            ...(typeOfLc !== undefined && typeOfLc !== null && typeOfLc !== "" && { lcType: typeOfLc }),
            ...(dispatchDate !== undefined && dispatchDate !== null && dispatchDate !== "" && { lastShipmentDate: dispatchDate }),
            ...(expiryDate !== undefined && expiryDate !== null && expiryDate !== "" && { expiryDate: expiryDate }),
            ...(placeOfExpiry !== undefined && placeOfExpiry !== null && placeOfExpiry !== "" && { expiryPlace: placeOfExpiry }),
            ...(currency !== undefined && currency !== null && currency !== "" && { currency: currency }),
            ...(ammount !== undefined && ammount !== null && ammount !== "" && { amount: ammount }),
            ...(nameOfApplicant !== undefined && nameOfApplicant !== null && nameOfApplicant !== "" && { applicantName: nameOfApplicant }),
            ...(applicantAddress !== undefined && applicantAddress !== null && applicantAddress !== "" && { applicantAddress: applicantAddress }),
            ...(beneficiaryName !== undefined && beneficiaryName !== null && beneficiaryName !== "" && { beneficiaryName: beneficiaryName }),
            ...(beneficiaryLocation !== undefined && beneficiaryLocation !== null && beneficiaryLocation !== "" && { beneficiaryAddress: beneficiaryLocation }),
            ...(purchaseOrderNumber !== undefined && purchaseOrderNumber !== null && purchaseOrderNumber !== "" && { purchaseOrderNumber: purchaseOrderNumber }),
            ...(purchaseOrderDate !== undefined && purchaseOrderDate !== null && purchaseOrderDate !== "" && { purchaseOrderDate: purchaseOrderDate }),
            ...(beneficiaryBankName !== undefined && beneficiaryBankName !== null && beneficiaryBankName !== "" && { beneficiaryBankName: beneficiaryBankName }),
            ...(beneficiaryBankAddress !== undefined && beneficiaryBankAddress !== null && beneficiaryBankAddress !== "" && { beneficiaryBankAddress: beneficiaryBankAddress }),
            ...(beneficiaryBankSwiftCode !== undefined && beneficiaryBankSwiftCode !== null && beneficiaryBankSwiftCode !== "" && { beneficiaryBankSwiftCode: beneficiaryBankSwiftCode }),
            ...(modeOfShipment !== undefined && modeOfShipment !== null && modeOfShipment !== "" && { shipmentMode: modeOfShipment }),
            ...(quantityTolerance !== undefined && quantityTolerance !== null && quantityTolerance !== "" && { toleranceQuantity: quantityTolerance }),
            ...(valueTolerance !== undefined && valueTolerance !== null && valueTolerance !== "" && { toleranceValue: valueTolerance }),
            ...(draftsAt !== undefined && draftsAt !== null && draftsAt !== "" && { draftsAt: draftsAt }),
            ...(dispatchFrom !== undefined && dispatchFrom !== null && dispatchFrom !== "" && { dispatchFrom: dispatchFrom }),
            ...(descriptionOfGood !== undefined && descriptionOfGood !== null && descriptionOfGood !== "" && { goodsDescription: descriptionOfGood }),
            ...(hsnCode !== undefined && hsnCode !== null && hsnCode !== "" && { hsnCode: hsnCode }),
            ...(ibanCode !== undefined && ibanCode !== null && ibanCode !== "" && { IBANcode: ibanCode }),
            lcDraftAttachement: JSON.stringify(LcDraft),
            purchaseOrderAttachment: JSON.stringify(purchaseOrder),
            performaInvoiceAttachment: JSON.stringify(proformaInvoice),
            creditReportAttachment: JSON.stringify(creditReport),
        }
        await mainStore.updateLcRequestTreasury(state?.item?.requestNumber, payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                notify(response?.data?.meta?.message)
                navigate("/treasury-journey-dashboard")
                setIsLoading(false)
            }
            else {
                notify(response?.data?.meta?.message, "danger")
                setIsLoading(false)
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
            setIsLoading(false)
        })


    }

    const closeApproval = () => {
        setShowTresuryCloseModal(true)
    }

    const getLcAuditDetail = async () => {
        let payload = {}
        await mainStore.getAuditLcDetail(state?.item?.requestNumber, payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                setGetAuditDetail(response?.data?.data?.audit)
            }
            else {
                notify(response?.data?.meta?.message, "danger")
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
        })
    }

    const handleEditTreasury = () => {
        setEditTreasuryDetails(!editTreasuryDetails)
    }
    const handlePreviousDownload = async (attachments) => {
        let payload = {
            filename: attachments
        }
        callDownloadApi(payload)
    }

    const handleFileChange = async (e, index) => {
        setIsLoading(true);
        const files = e.target.files;
        let formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i]);
            let params = 'LcRequest';
            await mainStore.uploadfile(params, formData, (response) => {
                if (response?.data?.meta?.status === "success") {
                    notify(response?.data?.meta?.message);
                    const updatedAmendments = amendments.map((amendment, i) =>
                        i === index ? { ...amendment, attachments: [...amendment.attachments, response.data.data] } : amendment
                    );
                    setAmendments(updatedAmendments);
                } else {
                    notify(response?.data?.meta?.message, "danger");
                }
                setIsLoading(false);
            },
                (error) => {
                    notify(error?.response?.data?.meta?.message, "danger");
                    setIsLoading(false);
                });
            formData.delete('file');
        }
    };


    const handleCancellationFileChange = async (e, index) => {
        setIsLoading(true);
        const files = e.target.files;
        let formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i]);
            let params = 'BgRequest';
            await mainStore.uploadfile(params, formData, (response) => {
                if (response?.data?.meta?.status === "success") {
                    notify(response?.data?.meta?.message);
                    const updatedCancellations = cancellations.map((cancellation, i) =>
                        i === index ? { ...cancellation, attachments: [...cancellation.attachments, response.data.data] } : cancellation
                    );
                    setCancellations(updatedCancellations);
                } else {
                    notify(response?.data?.meta?.message, "danger");
                }
                setIsLoading(false);
            },
                (error) => {
                    notify(error?.response?.data?.meta?.message, "danger");
                    setIsLoading(false);
                });
            formData.delete('file');
        }
    };

    useEffect(() => {
        if (state?.item?.lcrequestCancellation) {
            setCancellations(
                state?.item?.lcrequestCancellation?.map(cancellation => ({
                    id: cancellation.id,
                    lcCharge: cancellation.lcCharge || '',
                    gst: cancellation.gst || '',
                    stampCharges: cancellation.stampCharges || '',
                    bankChargesGlCode: cancellation.bankChargesGlCode || '',
                    gstGlCode: cancellation.gstGlCode || '',
                    comment: cancellation.comment || '',
                    attachments: cancellation.attachments ? JSON.parse(cancellation.attachments) : [],
                    cancellationDate: cancellation.cancellationDate ? cancellation.cancellationDate?.split('T')[0] : ''
                }))
            );
        }
    }, [state?.item?.lcrequestCancellation]);


    const handleCancellationInputData = (index, field, value) => {
        const updatedCancellations = cancellations.map((cancellation, i) =>
            i === index ? { ...cancellation, [field]: value } : cancellation
        );
        setCancellations(updatedCancellations);
    };

    useEffect(() => {
        if (state?.item?.lcrequestAmendment) {
            setAmendments(
                state?.item?.lcrequestAmendment?.map(amendment => ({
                    id: amendment.id,
                    lcCharge: amendment.lcCharge || '',
                    gst: amendment.gst || '',
                    stampCharges: amendment.stampCharges || '',
                    bankChargesGlCode: amendment.bankChargesGlCode || '',
                    gstGlCode: amendment.gstGlCode || '',
                    comment: amendment.comment || '',
                    attachments: amendment.attachments ? JSON.parse(amendment.attachments) : [],
                    expiryDate: amendment.expiryDate ? amendment.expiryDate?.split('T')[0] : '',
                    claimExpiryDate: amendment.claimExpiryDate ? amendment.claimExpiryDate?.split('T')[0] : '',
                    clause: amendment.clause || '',
                    others: amendment.others || ''
                }))
            );
        }
    }, [state?.item?.lcrequestAmendment]);

    const handleAmendmentInputData = (index, field, value) => {
        const updatedAmendments = amendments.map((amendment, i) =>
            i === index ? { ...amendment, [field]: value } : amendment
        );
        setAmendments(updatedAmendments);
    };

    const handleDispatchDateChange = (e) => {
        const selectedDate = e.target.value;
        setDispatchDate(selectedDate);
        const presentationDate = addDaysToDate(selectedDate, 21);
        setExpiryDate(presentationDate);
    };

    const addDaysToDate = (dateString, days) => {
        const date = new Date(dateString);
        date.setDate(date.getDate() + days);
        return date.toISOString()?.split('T')[0];
    };

    const [typeOfLcList, setTypeOfLcList] = useState([
        { name: "ILC  " },
        { name: "FLC  " },
        { name: "Others " }
    ])
    const [selectedTypeOfLC, setSelectedTypeOfLC] = useState(false)
    const [currencyList, setCurrencyList] = useState([])

    useEffect(() => {
        
       if(!typeOfLc){
        return
       }

        if (typeOfLc === "ILC  ") {
            setSelectedTypeOfLC(true);
            setCurrencyList([{ name: 'INR' }]);
        }else if(typeOfLc === "FLC  "){
            setSelectedTypeOfLC(false)
            setCurrencyList([
                { name: "USD" },
                { name: "EUR" },
                { name: "CHF" }
            ]);
        } else {
            setSelectedTypeOfLC(false)
            setCurrencyList([
                { name: "USD" },
                { name: "EUR" },
                { name: "INR" },
                { name: "CHF" }
            ]);
        }

    }, [typeOfLc]);

    const handleLcDraft = async (e) => {
        setIsLoading(true)
        const files = e.target.files;
        let formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i]);
            let params = 'LcRequest'
            await mainStore.uploadfile(params, formData, (response) => {
                if (response?.data?.meta?.status === "success") {
                    notify(response?.data?.meta?.message);
                    setLcDraft(prevState => [...prevState, response.data.data]);
                    setIsLoading(false)
                } else {
                    notify(response?.data?.meta?.message, "danger");
                    setIsLoading(false)
                }
            },
                (error) => {
                    notify(error?.response?.data?.meta?.message, "danger");
                    setIsLoading(false)
                });
            formData.delete('file');
            formData.delete('filepath');
        }
    }

    const handlePurchaseOrder = async (e) => {
        setIsLoading(true)
        const files = e.target.files;
        let formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i]);
            let params = 'LcRequest'
            await mainStore.uploadfile(params, formData, (response) => {
                if (response?.data?.meta?.status === "success") {
                    notify(response?.data?.meta?.message);
                    setPurchaseOrder(prevState => [...prevState, response.data.data]);
                    setIsLoading(false)
                } else {
                    notify(response?.data?.meta?.message, "danger");
                    setIsLoading(false)
                }
            },
                (error) => {
                    notify(error?.response?.data?.meta?.message, "danger");
                    setIsLoading(false)
                });
            formData.delete('file');
            formData.delete('filepath');
        }
    }

    const handleCreditReport = async (e) => {
        setIsLoading(true)
        const files = e.target.files;
        let formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i]);
            let params = 'LcRequest'
            await mainStore.uploadfile(params, formData, (response) => {
                if (response?.data?.meta?.status === "success") {
                    notify(response?.data?.meta?.message);
                    setCreditReport(prevState => [...prevState, response.data.data]);
                    setIsLoading(false)
                } else {
                    notify(response?.data?.meta?.message, "danger");
                    setIsLoading(false)
                }
            },
                (error) => {
                    notify(error?.response?.data?.meta?.message, "danger");
                    setIsLoading(false)
                });
            formData.delete('file');
            formData.delete('filepath');
        }
    }

    const handleProforma = async (e) => {
        setIsLoading(true)
        const files = e.target.files;
        let formData = new FormData();

        for (let i = 0; i < files.length; i++) {
            formData.append('file', files[i]);
            let params = 'LcRequest'
            await mainStore.uploadfile(params, formData, (response) => {
                if (response?.data?.meta?.status === "success") {
                    notify(response?.data?.meta?.message);
                    setProformaInvoice(prevState => [...prevState, response.data.data]);
                    setIsLoading(false)
                } else {
                    notify(response?.data?.meta?.message, "danger");
                    setIsLoading(false)
                }
            },
                (error) => {
                    notify(error?.response?.data?.meta?.message, "danger");
                    setIsLoading(false)
                });
            formData.delete('file');
            formData.delete('filepath');
        }
    }

    const handleRequestEntity = (e) => {
        setRequestEntity(e.target.value);
        fetchEntity(e.target.value)
        setBankAccountNumber("")
        setBankGlCode("")
        setBankName("")
    }

    const handleBankName = (e) => {
        setBankName(e.target.value)
        fetchBankAccountDetails(e.target.value)
    }

    const handleBankAccountNumber = (e) => {
        setBankAccountNumber(e.target.value)
        fetchBankGlCode(e.target.value)
    }


    const fetchBankNameData = async (entityId) => {
      
        mainStore.getBankDetails(entityId, (status, response) => {
            if (response?.meta?.message === "success") {
                setBankNameData(response?.data?.bankList)
            } else {
                notify(response?.meta?.message, "danger")
            }
        }, (error) => {
            notify(error?.response?.meta?.message, "danger")
        })
    }

    const fetchBankAccountDetails = async (num) => {
        let payload = {
            entityCode: entityId,
            bankName: bankName ? bankName : num
        }
        mainStore.getBankAccountDetails(payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                setBankAccountData(response?.data?.data)
            } else {
                notify(response?.data?.meta?.message, "danger")
            }
        }, (error) => {
            notify(error?.response?.data?.meta?.message, "danger")
        })
    }

    useEffect(() => {
        if (entityId && bankName) {
            fetchBankAccountDetails()
        }
    }, [entityId, bankName])


    const fetchBankGlCode = async (num) => {
        let payload = {
            bankName: bankName,
            entityCode: entityId,
            accountNumber: bankAccountNumber ? bankAccountNumber : num
        }
        mainStore.getBankGlCode(payload, (response) => {
            if (response?.data?.meta?.status === "success") {
                setBankGlCodeData(response?.data?.data)
            } else {
                notify(response?.data?.meta?.message, "danger")
            }
        }, (err) => {
            notify(err?.response?.data?.meta?.message, "danger")
        })
    }

    useEffect(() => {
        if (bankName && entityId && bankAccountNumber) {
            fetchBankGlCode()
        }
    }, [bankAccountNumber, entityId, bankName])

    const getFormattedDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
      };


    return (
        <div className="main-container">
            <div className="right-view">
                {isLoading && <Loader />}
                <Header title={" LC > Issue"} />
                <div className="row">
                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Requester"}
                            cardboldtitle={"Details"}
                            toprightview={editTreasuryDetails ? "" : <img src={editImg} alt="" className="edit-btn" onClick={handleEditTreasury} />}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Request Number"
                                            value={requestNumber}
                                            onChange={(e) => setRequestNumber(e.target.value)}
                                            placeholder="Request Number"
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            label="Requester Name"
                                            value={requestName}
                                            onChange={(e) => setRequestName(e.target.value)}
                                            placeholder="Requester Name"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            label="Requester Mail ID"
                                            value={requesterMail}
                                            onChange={(e) => setRequesterMail(e.target.value)}
                                            placeholder="Requester mail ID"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <div className="custominput-container">
                                            <div className="custom-input">
                                                <label htmlFor="">Entity </label>
                                                <div style={{ position: "relative" }}>
                                                    <select
                                                        className="form-control"
                                                        value={requestEntity}
                                                        disabled={!editTreasuryDetails}
                                                        onChange={handleRequestEntity}
                                                    >
                                                        <option value="">Select Entity</option>
                                                        {entity?.map((entity, index) => (
                                                            <option key={index} value={entity.name}>
                                                                {entity.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <i className="fas fa-chevron-down approver-drop-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Unit"
                                            readOnly={editTreasuryDetails ? false : true}
                                            value={requestUnit}
                                            onChange={(e) => setRequestUnit(e.target.value)}
                                            placeholder="Unit"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <div className="custominput-container">
                                            <div className="custom-input">
                                                <label htmlFor="">Line Of Service </label>
                                                <div style={{ position: "relative" }}>
                                                    <select
                                                        className="form-control"
                                                        disabled={!editTreasuryDetails}
                                                        value={division}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option value="">Select Line Of Service </option>
                                                        {lineOfService?.map((item, index) => (
                                                            <option key={index} value={item?.name}>
                                                                {item?.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <i className="fas fa-chevron-down approver-drop-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Comment"
                                            value={requesterDetailComments}
                                            readOnly={editTreasuryDetails ? false : true}
                                            placeholder="Comment"
                                            onChange={(e) => setRequesterDetailComment(e.target.value)}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>
                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Approver"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div>
                                    {approverInfo?.map((approver, index) => (
                                        <div key={index} className="row">
                                            <div className="col-md-4">
                                                <div className="custominput-container">
                                                    <div className="custom-input">
                                                        <label htmlFor={`approverName${index}`}>
                                                            {/* Approver L{index + 1} Name{" "}
                                                            <span style={{ color: "red" }}>*</span> */}
                                                            {index === 0 && <>Approver Purchase <span style={{ color: 'red' }}>*</span></>}
                                                            {index === 1 && <>Approver Finance <span style={{ color: 'red' }}>*</span></>}
                                                        </label>
                                                        <div style={{ position: "relative" }}>
                                                            <select
                                                                id={`approverName${index}`}
                                                                className="form-control"
                                                                value={approver?.approverName}
                                                                disabled={!editTreasuryDetails}
                                                                onChange={(e) => {
                                                                    const selectedApprover = approverList.find(
                                                                        (approver) =>
                                                                            approver.name === e.target.value
                                                                    );
                                                                    handleApproverChange(index, "approverName", e.target.value);
                                                                    handleApproverChange(index, "approverId", selectedApprover.id);
                                                                    handleApproverChange(index, "approverEmail", selectedApprover.email);
                                                                    handleApproverChange(index, "approverDepartment", selectedApprover.user_department);
                                                                }}
                                                            >
                                                                <option value="">Select Approver</option>
                                                                {index === 0 &&
                                                                    approverList.filter((approver) => approver.user_department === "Purchase").map((filteredApprover, filteredIndex) => (
                                                                        <option key={filteredIndex} value={filteredApprover.name}> {filteredApprover.name} </option>
                                                                    ))}
                                                                {index === 1 &&
                                                                    approverList.filter((approver) => approver.user_department === "Finance").map((filteredApprover, filteredIndex) => (
                                                                        <option key={filteredIndex} value={filteredApprover.name}>{filteredApprover.name} </option>
                                                                    ))}
                                                            </select>
                                                            <i className="fas fa-chevron-down approver-drop-down"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <CustomInput
                                                    require={true}
                                                    readOnly={true}
                                                    label="Department"
                                                    value={approver.approverDepartment}
                                                    onChange={(e) =>
                                                        handleApproverChange(
                                                            index,
                                                            "approverDepartment",
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder="Department"
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <CustomInput
                                                    require={true}
                                                    readOnly={true}
                                                    label="Mail ID"
                                                    value={approver.approverEmail}
                                                    onChange={(e) =>
                                                        handleApproverChange(
                                                            index,
                                                            "approverEmail",
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder="Mail ID"
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    <div className="col-md-4">
                                        <CustomInput
                                            readOnly={editTreasuryDetails ? false : true}
                                            label="Comment"
                                            placeholder="Comment"
                                            value={approverDetailsComment}
                                            onChange={(e) => setApproverDetailsComment(e.target.value)}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Bank "}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="custominput-container">
                                            <div className="custom-input">
                                                <label htmlFor="" >Bank Name </label>
                                                <div style={{ position: 'relative' }}>
                                                    <select
                                                        className="form-control"
                                                        value={bankName}
                                                        onChange={handleBankName}
                                                    >
                                                        <option value="">Select Bank Name</option>
                                                        {bankNameData?.map((entity, index) => (
                                                            <option key={index} value={entity}>
                                                                {entity}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <i className="fas fa-chevron-down approver-drop-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="custominput-container">
                                            <div className="custom-input">
                                                <label htmlFor="" >Bank Account Number</label>
                                                <div style={{ position: 'relative' }}>
                                                    <select
                                                        className="form-control"
                                                        value={bankAccountNumber}
                                                        onChange={handleBankAccountNumber}
                                                    >
                                                        <option value="">Select Bank Account Number</option>
                                                        {bankAccountData?.map((item, index) => (
                                                            <option key={index} value={item?.bank_account_number}>
                                                                {item?.bank_account_number}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <i className="fas fa-chevron-down approver-drop-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="custominput-container">
                                            <div className="custom-input">
                                                <label htmlFor="" >Bank GL Code</label>
                                                <div style={{ position: 'relative' }}>
                                                    <select
                                                        className="form-control"
                                                        value={bankGlCode}
                                                        onChange={(e) => setBankGlCode(e.target.value)}
                                                    >
                                                        <option value="">Select Bank GL Code</option>
                                                        {bankGlCodeData?.map((item, index) => (
                                                            <option key={index} value={item?.glcode}>
                                                                {item?.glcode}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <i className="fas fa-chevron-down approver-drop-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Comment"
                                            value={bankDetailComment}
                                            placeholder="Comment"
                                            onChange={(e) => setBankDetailComment(e.target.value)}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Letter of Credit"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Reference Number"
                                            value={refrenceNumber}
                                            onChange={(e) => setRefrenceNumber(e.target.value)}
                                            placeholder="Reference Number"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <div className="custominput-container">
                                            <div className="custom-input">
                                                <label htmlFor="" >Type of LC <span style={{ color: 'red' }}>*</span></label>
                                                <div style={{ position: 'relative' }}>
                                                    <select
                                                        className="form-control"
                                                        value={typeOfLc}
                                                        disabled={!editTreasuryDetails}
                                                        onChange={(e) => setTypeOfLc(e.target.value)}
                                                    >
                                                        <option value="">Select Type of LC</option>
                                                        {typeOfLcList.map((item, index) => (
                                                            <option key={index} value={item.name}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <i className="fas fa-chevron-down approver-drop-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Issuance Date"
                                            value={inssuanceDate && getFormattedDate(inssuanceDate)}
                                            type="date"
                                            onChange={(e) => setInssuanceDate(e.target.value)}
                                            placeholder="Issuance Date"
                                            // min={new Date().toISOString()?.split('T')[0]}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={editTreasuryDetails ? false : true}
                                            type="date"
                                            label="Date of Shipment/dispatch"
                                            value={dispatchDate && getFormattedDate(dispatchDate)}
                                            placeholder="Date of Shipment/dispatch"
                                            onChange={handleDispatchDateChange}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={true}
                                            type="date"
                                            label="Expiry Date"
                                            value={expiryDate && getFormattedDate(expiryDate)}
                                            placeholder="Expiry Date"
                                            onChange={(e) => setExpiryDate(e.target?.value)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={editTreasuryDetails ? false : true}
                                            label="Place of Expiry"
                                            value={placeOfExpiry}
                                            placeholder="Place of Expiry"
                                            onChange={(e) => setPlaceOfExpiry(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <div className="custominput-container">
                                            <div className="custom-input">
                                                <label htmlFor="" >Currency <span style={{ color: 'red' }}>*</span></label>
                                                <div style={{ position: 'relative' }}>
                                                    <select
                                                        className="form-control"
                                                        value={currency}
                                                        onChange={(e) => setCurrency(e.target.value)}
                                                        disabled={!editTreasuryDetails}
                                                    >
                                                        <option value="">Select Currency</option>
                                                        {currencyList.map((item, index) => (
                                                            <option key={index} value={item.name}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <i className="fas fa-chevron-down approver-drop-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Amount"
                                            readOnly={editTreasuryDetails ? false : true}
                                            value={ammount}
                                            placeholder="Amount"
                                            onChange={(e) => setAmmount(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <div className="custominput-container">
                                            <div className="custom-input">
                                                <label htmlFor="" >Name of Applicant <span style={{ color: 'red' }}>*</span> </label>
                                                <div style={{ position: 'relative' }}>
                                                    <select
                                                        className="form-control"
                                                        value={nameOfApplicant}
                                                        disabled={!editTreasuryDetails}
                                                        onChange={(e) => { setNameOfApplicant(e.target.value) }}
                                                    >
                                                        <option value="">Select Name of Applicant</option>
                                                        {entity.map((entity, index) => (
                                                            <option key={index} value={entity.name}>
                                                                {entity.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <i className="fas fa-chevron-down approver-drop-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={editTreasuryDetails ? false : true}
                                            label="Address of Applicant"
                                            value={applicantAddress}
                                            placeholder="Address of Applicant"
                                            onChange={(e) => setApplicantAddress(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={editTreasuryDetails ? false : true}
                                            label="Name of Beneficiary"
                                            value={beneficiaryName}
                                            placeholder="Name of Beneficiary"
                                            onChange={(e) => setBeneficiaryName(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={editTreasuryDetails ? false : true}
                                            label="Address of Beneficiary"
                                            value={beneficiaryLocation}
                                            placeholder="Address of Beneficiary"
                                            onChange={(e) => setBeneficiaryLocation(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={editTreasuryDetails ? false : true}
                                            label="Purchase Order No."
                                            value={purchaseOrderNumber}
                                            placeholder="Purchase Order No."
                                            onChange={(e) => setPurchaseOrderNumber(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={editTreasuryDetails ? false : true}
                                            label="Purchase Order Date"
                                            type="date"
                                            value={ purchaseOrderDate && getFormattedDate(purchaseOrderDate)}
                                            placeholder="Purchase Order Date"
                                            onChange={(e) => setPurchaseOrderDate(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={editTreasuryDetails ? false : true}
                                            label="Beneficiary Bank Name"
                                            value={beneficiaryBankName}
                                            placeholder="Beneficiary Bank Name"
                                            onChange={(e) => setBeneficiaryBankName(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={editTreasuryDetails ? false : true}
                                            label="Beneficiary Bank Address"
                                            value={beneficiaryBankAddress}
                                            placeholder="Beneficiary Bank Address"
                                            onChange={(e) => setBeneficiaryBankAddress(e.target.value)}

                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={editTreasuryDetails ? false : true}
                                            label="Beneficiary Bank Swift Code"
                                            value={beneficiaryBankSwiftCode}
                                            placeholder="Beneficiary Bank Swift Code"
                                            onChange={(e) => setBeneficiaryBankSwiftCode(e.target.value)}

                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={!editTreasuryDetails}
                                            label="Mode of Shipment"
                                            value={modeOfShipment}
                                            placeholder="Mode of Shipment"
                                            onChange={(e) => setModeOfShipment(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={!editTreasuryDetails}
                                            label="Quantity Tolerance"
                                            value={quantityTolerance}
                                            placeholder="Quantity Tolerance"
                                            onChange={(e) => setQuantityTolerance(e.target.value)}

                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={!editTreasuryDetails}
                                            label="Value Tolerance"
                                            value={valueTolerance}
                                            placeholder="Value Tolerance"
                                            onChange={(e) => setValueTolerance(e.target.value)}

                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Drafts At"
                                            value={draftsAt}
                                            readOnly={!editTreasuryDetails}
                                            placeholder="Drafts At"
                                            onChange={(e) => setDraftsAt(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            label="Dispatch From"
                                            value={dispatchFrom}
                                            readOnly={!editTreasuryDetails}
                                            placeholder="Dispatch From"
                                            onChange={(e) => setDispatchFrom(e.target.value)}

                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={!editTreasuryDetails}
                                            label="Description of Goods"
                                            value={descriptionOfGood}
                                            placeholder="Description of Goods"
                                            onChange={(e) => setDescriptionOfGood(e.target.value)}

                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            require={true}
                                            readOnly={!editTreasuryDetails}
                                            label="HSN Code"
                                            value={hsnCode}
                                            placeholder="HSN Code"
                                            onChange={(e) => setHsnCode(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            readOnly={!editTreasuryDetails}
                                            label="IBAN Code"
                                            value={ibanCode}
                                            placeholder="IBAN Code"
                                            onChange={(e) => setIbanCode(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Comment"
                                            value={letterOfCreditDetailComment}
                                            readOnly={editTreasuryDetails ? false : true}
                                            placeholder="Comment"
                                            onChange={(e) => setLetterOfCreditDetailComment(e.target.value)}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Bank Charges"}
                            cardboldtitle={"Details"}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="LC Charges"
                                            value={bgCharges}
                                            onChange={(e) => setBgCharges(e.target.value)}
                                            placeholder="LC Charges"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="GST"
                                            value={gst}
                                            onChange={(e) => setGst(e.target.value)}
                                            placeholder="GST"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Bank Charges GL Code"
                                            value={bgChargesGlCode}
                                            onChange={(e) => setBgChargesGlCode(e.target.value)}
                                            placeholder="Bank Charges GL Code"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="GST GL Code"
                                            value={gstGlCode}
                                            onChange={(e) => setGstGlCode(e.target.value)}
                                            placeholder="GST GL Code"
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Comment"
                                            value={bankChargesComment}
                                            // readOnly={editTreasuryDetails ? false : true}
                                            placeholder="Comment"
                                            onChange={(e) => setBankChargesComment(e.target.value)}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>

                    <div className="col-md-12">
                        <CustomCard
                            cardboldtitle={"Attachments"}
                            cardbody={
                                <div className="row">
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="LC Draft"
                                            require={true}
                                            readOnly={!editTreasuryDetails}
                                            placeholder="Browse File"
                                            {...(editTreasuryDetails ? { type: "file", onChange: handleLcDraft } : { type: "text" })}
                                        />
                                        <div className="uploaded-images">
                                            {LcDraft.map((item, index) => (
                                                <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: '8px' }} onClick={() => handleLcDraftDownload(item)} className="url-download">
                                                    {item}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                        require={true}
                                            label="Purchase Order"
                                            readOnly={!editTreasuryDetails}
                                            placeholder="Browse File"
                                            {...(editTreasuryDetails ? { type: "file", onChange: handlePurchaseOrder } : { type: "text" })}
                                        />
                                        <div className="uploaded-images">
                                            {purchaseOrder.map((item, index) => (
                                                <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: '8px' }} onClick={() => handlePurchaseOrderDownload(item)} className="url-download">
                                                    {item}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                        require={true}
                                            label="Proforma Invoice"
                                            placeholder="Browse File"
                                            readOnly={!editTreasuryDetails}
                                            {...(editTreasuryDetails ? { type: "file", onChange: handleProforma } : { type: "text" })}
                                        />
                                        <div className="uploaded-images">
                                            {proformaInvoice.map((item, index) => (
                                                <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: '8px' }} onClick={() => handleProformaDownload(item)} className="url-download">
                                                    {item}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                          require={selectedTypeOfLC? false :true}
                                            label="Credit Report"
                                            readOnly={!editTreasuryDetails}
                                            placeholder="Browse File"
                                            {...(editTreasuryDetails ? { type: "file", onChange: handleCreditReport } : { type: "text" })}

                                        />
                                        <div className="uploaded-images">
                                            {creditReport.map((item, index) => (
                                                <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: '8px' }} onClick={() => handleCreditReportdownload(item)} className="url-download">
                                                    {item}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <CustomInput
                                            label="Comment"
                                            value={attachmentComment}
                                            readOnly={!editTreasuryDetails}
                                            placeholder="Comment"
                                            onChange={(e) => setAttachmentComment(e.target.value)}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </div>


                    {amendments?.length > 0 && (
                        <div className="col-md-12">
                            {amendments?.map((amendment, index) => (
                                <CustomCard
                                    key={amendment.id}
                                    cardboldtitle={`Amendment ${index + 1}`}
                                    cardbody={
                                        <div className="row">
                                            <div className="col-md-3">
                                                <CustomInput
                                                    readOnly={!editTreasuryDetails}
                                                    label="Expiry Date"
                                                    placeholder="Expiry Date"
                                                    type="date"
                                                    value={amendment.expiryDate}
                                                    onChange={(e) => handleAmendmentInputData(index, 'expiryDate', e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    readOnly={!editTreasuryDetails}
                                                    label="Claim Expiry Date"
                                                    placeholder="Claim Expiry Date"
                                                    type="date"
                                                    min={amendment.expiryDate}
                                                    value={amendment.claimExpiryDate}
                                                    onChange={(e) => handleAmendmentInputData(index, 'claimExpiryDate', e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    readOnly={!editTreasuryDetails}
                                                    label="Clause"
                                                    placeholder="Clause"
                                                    value={amendment.clause}
                                                    onChange={(e) => handleAmendmentInputData(index, 'clause', e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    readOnly={!editTreasuryDetails}
                                                    label="Others"
                                                    placeholder="Others"
                                                    value={amendment.others}
                                                    onChange={(e) => handleAmendmentInputData(index, 'others', e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    require={true}
                                                    label="Attachment"
                                                    placeholder="Browse file"
                                                    readOnly={!editTreasuryDetails}
                                                    {...(editTreasuryDetails ? { type: "file", onChange: (e) => handleFileChange(e, index) } : { type: "text" })}
                                                />
                                                {amendment.attachments.map((item, i) => (
                                                    <div key={i} style={{ cursor: "pointer", color: "#65a6fa" }} onClick={() => handlePreviousDownload(item)} className=" url-download mb-3">
                                                        {item}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    label="LC Charges"
                                                    value={amendment.lcCharge}
                                                    onChange={(e) => handleAmendmentInputData(index, 'lcCharge', e.target.value)}
                                                    placeholder="LC Charges"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    label="GST"
                                                    placeholder="GST"
                                                    value={amendment.gst}
                                                    onChange={(e) => handleAmendmentInputData(index, 'gst', e.target.value)}
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    label="Stamp Charges"
                                                    value={amendment.stampCharges}
                                                    onChange={(e) => handleAmendmentInputData(index, 'stampCharges', e.target.value)}
                                                    placeholder="Stamp Charges"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    label="Bank Charges GL Code"
                                                    value={amendment.bankChargesGlCode}
                                                    onChange={(e) => handleAmendmentInputData(index, 'bankChargesGlCode', e.target.value)}
                                                    placeholder="Bank Charges GL Code"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    label="GST GL Code"
                                                    value={amendment.gstGlCode}
                                                    onChange={(e) => handleAmendmentInputData(index, 'gstGlCode', e.target.value)}
                                                    placeholder="GST GL Code"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    readOnly={!editTreasuryDetails}
                                                    label="Comment"
                                                    placeholder="Comment"
                                                    value={amendment.comment}
                                                    onChange={(e) => handleAmendmentInputData(index, 'comment', e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    }
                                />
                            ))}
                        </div>
                    )}

                    {cancellations?.length > 0 && (
                        <div className="col-md-12">
                            {cancellations?.map((cancellation, index) => (
                                <CustomCard
                                    key={cancellation.id}
                                    cardboldtitle={`Cancellation ${index + 1}`}
                                    cardbody={
                                        <div className="row">
                                            <div className="col-md-3">
                                                <CustomInput
                                                    readOnly={!editTreasuryDetails}
                                                    label="Claim Expiry Date"
                                                    placeholder="Claim Expiry Date"
                                                    type="date"
                                                    value={cancellation.cancellationDate}
                                                    onChange={(e) =>
                                                        handleCancellationInputData(index, 'cancellationDate', e.target.value)
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    require={true}
                                                    label="Attachment"
                                                    placeholder="Browse file"
                                                    readOnly={!editTreasuryDetails}
                                                    {...(editTreasuryDetails ? { type: "file", onChange: (e) => handleCancellationFileChange(e, index) } : { type: "text" })}

                                                />
                                                {cancellation.attachments.map((item, i) => (
                                                    <div key={i} style={{ cursor: 'pointer', color: '#65a6fa' }} onClick={() => handleCancellationDownload(item)} className="url-download mb-3">
                                                        {item}
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    label="LC Charges"
                                                    value={cancellation.lcCharge}
                                                    onChange={(e) =>
                                                        handleCancellationInputData(index, 'lcCharge', e.target.value)
                                                    }
                                                    placeholder="LC Charges"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    label="GST"
                                                    placeholder="GST"
                                                    value={cancellation.gst}
                                                    onChange={(e) =>
                                                        handleCancellationInputData(index, 'gst', e.target.value)
                                                    }
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    label="Stamp Charges"
                                                    value={cancellation.stampCharges}
                                                    onChange={(e) =>
                                                        handleCancellationInputData(index, 'stampCharges', e.target.value)
                                                    }
                                                    placeholder="Stamp Charges"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    label="Bank Charges GL Code"
                                                    value={cancellation.bankChargesGlCode}
                                                    onChange={(e) =>
                                                        handleCancellationInputData(index, 'bankChargesGlCode', e.target.value)
                                                    }
                                                    placeholder="Bank Charges GL Code"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    label="GST GL Code"
                                                    value={cancellation.gstGlCode}
                                                    onChange={(e) =>
                                                        handleCancellationInputData(index, 'gstGlCode', e.target.value)
                                                    }
                                                    placeholder="GST GL Code"
                                                />
                                            </div>
                                            <div className="col-md-3">
                                                <CustomInput
                                                    readOnly={!editTreasuryDetails}
                                                    label="Comment"
                                                    placeholder="Comment"
                                                    value={cancellation.comment}
                                                    onChange={(e) =>
                                                        handleCancellationInputData(index, 'comment', e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    }
                                />
                            ))}
                        </div>
                    )}

                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={"Audit"}
                            cardboldtitle={" trail"}
                            toprightview={
                                <button className="export-btn">
                                    <ExcelFile element={<div> <img width={20} className="me-2" src={require("../../assets/images/excel.png")} />Export</div>}>
                                        <ExcelSheet data={getAuditDetail?.map((item, i) => ({
                                            ...item, index: i + 1, created_at: item?.created_at && `${item.created_at?.split("T")[0]} ${item.created_at?.split("T")[1]?.split(".")[0].substring(0, 5)}`,
                                            role: item?.role,
                                        }))} name="Sheet">
                                            <ExcelColumn label="Status Date & Time" value="created_at" />
                                            <ExcelColumn label="Name" value="userName" />
                                            <ExcelColumn label="Role" value="role" />
                                            <ExcelColumn label="Action Required" value="status" />
                                        </ExcelSheet>
                                    </ExcelFile>
                                </button>
                            }
                            cardbody={
                                <React.Fragment>
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead class="table-customlight">
                                                <tr>
                                                    <th className="auditTrail">Status Date & Time</th>
                                                    <th className="auditTrail">Name</th>
                                                    <th className="auditTrail">Role</th>
                                                    <th className="auditTrail">Action Required</th>
                                                </tr>
                                            </thead>
                                            {getAuditDetail?.length > 0 && (
                                                <tbody>
                                                    {getAuditDetail?.map((item, i) => {
                                                        return (
                                                            <tr key={item?.requestNumber}>
                                                                <td scope="row"> <FormatDate dateString={item?.created_at} /></td>
                                                                <td>{item?.userName}</td>
                                                                <td>{item?.role}</td>
                                                                <td className="status-box"><span className="status"></span>{item?.status}</td>

                                                            </tr>)
                                                    })}
                                                </tbody>)}
                                        </table>
                                    </div>
                                </React.Fragment>
                            }
                        />
                    </div>
                    <div className="col-md-12">
                        <CustomCard
                            cardtitle={""}
                            cardboldtitle={"Chat"}
                            cardbody={
                                <div className="row">
                                    {chat?.length === 0 && <>
                                        <div className="col-md-12 chat-button">
                                            <CustomButton style={"btn--outline me-3"} children={"Initiate Chat Trail"} onClick={toggleChatButton} />
                                        </div></>}

                                    {(startChat || (chat?.length > 0)) && <>
                                        {chat?.length > 0 && <>
                                            {
                                                chat.map((item, i) => (
                                                    <div key={i} className="col-md-12 chat-left">
                                                        <div className="chat-container">
                                                            <h4 className="chat-username">{item?.userName}({item?.userRole})</h4>
                                                            <div className="chat-username-box">
                                                                <p className="chat-massege">{item?.message}</p>
                                                                <p className="chat-time"> <FormatDate dateString={item?.created_at} /></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            } </>}
                                        <div className="col-md-6 " style={{ width: "44%" }}>
                                            <CustomInput
                                                label="Comment Box"
                                                value={chatComment}
                                                onChange={(e) => setChatComment(e.target.value)}
                                                placeholder="Comment Box"
                                            />
                                        </div>
                                        <div className="col-md-2 chat-button">
                                            <CustomButton style={"btn--outline me-3"} children={"Send"} onClick={handleSendMassege} />
                                        </div>
                                    </>}
                                </div>
                            }
                        />
                    </div>



                    <div className="col-md-12 text-end">
                        <CustomButton style={"btn--outline me-3"} children={"Save"} onClick={UpdateBgRequestByTreasury} />
                        <CustomButton children={"Close"} onClick={closeApproval} />
                    </div>
                </div>
            </div>
            <TreasuryCloseLcModal
                show={showtresuryCloseModal}
                hide={() => {
                    setShowTresuryCloseModal(false)
                }}
                requestNumber={state?.item?.requestNumber}
            />
        </div>
    );
};

export default TreasuryLcIssue;
