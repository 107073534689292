import React, { useEffect, useState } from "react";
import "./style.scss";
import Header from "../../../component/Header";
import CustomCard from "../../../component/CustomeCard";
import CustomInput from "../../../component/CustomInput";
import CustomButton from "../../../component/Button/Button";
import notify from "../../../shared/notify";
import { useStores } from "../../../stores"
import { useNavigate } from "react-router-dom";
import isEmail from 'validator/lib/isEmail';
import Loader from "../../../component/helper/Loader/loader";



const Issuance = () => {
  const { mainStore } = useStores()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [requestNumber, setRequestNumber] = useState();
  const [requestName] = useState(sessionStorage?.name);
  const [requesterMail] = useState(sessionStorage?.email);
  const [requestEntity, setRequestEntity] = useState([]);
  const [entity, setEntity] = useState("")
  const [requestUnit, setRequestUnit] = useState();
  const [approverInfo, setApproverInfo] = useState([
    { approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L1", approverId: "" },
    { approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L2", approverId: "" }
  ]);
  const [bankName, setBankName] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState();
  const [bankGlCode, setBankGlCode] = useState();
  const [refrenceNumber, setRefrenceNumber] = useState();
  const [applicantName, setApplicantName] = useState("");
  const [applicantLocation, setApplicantLocation] = useState("");
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [beneficiaryLocation, setBeneficiaryLocation] = useState("");
  const [inssuanceDate, setInssuanceDate] = useState("");
  const [expiryDate, setExpiryDate] = useState();
  const [currency, setCurrency] = useState();
  const [claimExpiryDate, setClaimExpiryDate] = useState();
  const [ammount, setAmmount] = useState();
  const [printLocation, setPrintLoation] = useState();
  const [typeOfBg, setTypeOfBg] = useState();
  const [paybleLocation, setPaybleLocation] = useState();
  const [bgCharges, setBgCharges] = useState();
  const [gst, setGst] = useState();
  const [stampcharges, setStampCharges] = useState();
  const [bgChargesGlCode, setBgChargesGlCode] = useState();
  const [gstGlCode, setGstGlCode] = useState();
  const [bgText, setBgText] = useState([]);
  const [purchaseOrder, setPurchaseOrder] = useState([]);
  const [approverList, setApproverList] = useState([])

  const [lineOfService , setLineOfService] = useState([])
  const [division, setDivision] = useState(sessionStorage.getItem("line_of_service"));
  const Viewer = sessionStorage.getItem("role")


  const [typeOfBgList] = useState([
    { name: "PBG " },
    { name: "FBG " },
    { name: "EMD " },
    { name: "Others" }
  ])
  const [currencyList] = useState([
    { name: "USD" },
    { name: "EUR" },
    { name: "INR" },
    { name: "CHF" }
  ])

  const [requesterDetailComments, setRequesterDetailComments] = useState("")
  const [approverDetailsComment, setApproverDetailsComment] = useState("")
  const [bankDetailComment, setBankDetailComment] = useState("")
  const [bankGuaranteeComment, setBankGuaranteeComment] = useState("")
  const [bankChargesComment, setBankChargesComment] = useState("")
  const [attachmentComment, setAttachmentComment] = useState("")
  const isValidApproverInfo = approverInfo.every(approver => (
    approver.approverName !== "" &&
    approver.approverDepartment !== "" &&
    approver.approverEmail !== "" &&
    approver.approverId !== ""
  ));

  const fetchEntity = async () => {
    await mainStore.getEntity((status, response) => {
      if (response?.meta?.status === "success") {
        setRequestEntity(response?.data?.entitites)
      } else {
        notify(response?.meta?.message, "danger");
      }
    },
      (error) => {
        notify(error?.response?.meta?.message, "danger");
      }
    );
  }



  const handlePurchaseOrder = async (e) => {
    setIsLoading(true)
    const files = e.target.files;
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
      let params = 'BgRequest'
      await mainStore.uploadfile(params, formData, (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message);
          setPurchaseOrder(prevState => [...prevState, response.data.data]);
          setIsLoading(false)
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false)
        }
      },
        (error) => {
          notify(error?.response?.data?.meta?.message, "danger");
          setIsLoading(false)
        });
      formData.delete('file');
      formData.delete('filepath');
    }
  }

  const handleBgText = async (e) => {
    setIsLoading(true)
    const files = e.target.files;
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
      let params = 'BgRequest'
      await mainStore.uploadfile(params, formData, (response) => {
        if (response?.data?.meta?.status === "success") {
          notify(response?.data?.meta?.message);
          setBgText(prevState => [...prevState, response.data.data]);
          setIsLoading(false)
        } else {
          notify(response?.data?.meta?.message, "danger");
          setIsLoading(false)
        }
      },
        (error) => {
          notify(error?.response?.data?.meta?.message, "danger");
          setIsLoading(false)
        });
      formData.delete('file');
      formData.delete('filepath');
    }
  }


  const handleBgTextdownload = async (filename) => {
    let payload = {
      filename: filename
    }
    callDownloadApi(payload)
  }


  const handlePurchasedownload = async (filename) => {
    let payload = {
      filename: filename
    }
    callDownloadApi(payload)
  }


  const callDownloadApi = async (payload) => {
    setIsLoading(true);
    await mainStore.downloadImage(payload, (response) => {
      if (response?.data?.meta?.status === "success") {
        notify(response?.data?.meta?.message);
        setIsLoading(false);
        const base64File = response?.data?.data?.base64File;
        const mimeType = response?.data?.data?.mimeType;
        const fileNameParts = response?.data?.data?.filename?.split("-");
        const fileName = fileNameParts.slice(1).join("-");
        if (base64File && mimeType) {
          const downloadLink = document.createElement('a');
          downloadLink.href = `data:${mimeType};base64,${base64File}`;
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } else {
          notify("Download link or File type not found", "danger");
        }
      } else {
        notify(response?.data?.meta?.message, "danger");
        setIsLoading(false);
      }
    },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false);
      });
  };


  useEffect(() => {
    fetchEntity()
    fetchApproverDetail()
  }, [])

  const handleApproverChange = (index, field, value) => {
    const updatedApproverInfo = [...approverInfo];
    updatedApproverInfo[index][field] = value;
    setApproverInfo(updatedApproverInfo);
  };


  const submitForApproval = async () => {
    setIsLoading(true)
    if (
      requestName === "undefined" ||
      requestName === "" ||
      requestName === null
    ) {
      notify("Fill The Request Name", "danger");
      return setIsLoading(false)
    }

    if (
      requestEntity === "undefined" ||
      requestEntity === "" ||
      requestEntity === null
    ) {
      notify("Fill The Request Entity", "danger");
      return setIsLoading(false)
    }
    if (
      requestUnit === "undefined" ||
      requestUnit === "" ||
      requestUnit === null
    ) {
      notify("Fill The Request Unit", "danger");
      return setIsLoading(false)
    }
    if (
      applicantName === "undefined" ||
      applicantName === "" ||
      applicantName === null
    ) {
      notify("Fill The Applicant Name", "danger");
      return setIsLoading(false)
    }
    if (
      applicantLocation === "undefined" ||
      applicantLocation === "" ||
      applicantLocation === null
    ) {
      notify("Fill The Applicant Location", "danger");
      return setIsLoading(false)
    }
    if (
      beneficiaryName === "undefined" ||
      beneficiaryName === "" ||
      beneficiaryName === null
    ) {
      notify("Fill The Beneficiary Name", "danger");
      return setIsLoading(false)
    }
    if (
      beneficiaryLocation === "undefined" ||
      beneficiaryLocation === "" ||
      beneficiaryLocation === null
    ) {
      notify("Fill The Beneficiary Location", "danger");
      return setIsLoading(false)
    }
    if (
      expiryDate === "undefined" ||
      expiryDate === "" ||
      expiryDate === null
    ) {
      notify("Fill The Expiry Date", "danger");
      return setIsLoading(false)
    }
    if (currency === "undefined" || currency === "" || currency === null) {
      notify("Fill The Currency", "danger");
      return setIsLoading(false)
    }
    if (
      claimExpiryDate === "undefined" ||
      claimExpiryDate === "" ||
      claimExpiryDate === null
    ) {
      notify("Fill The Claim Expiry Date", "danger");
      return setIsLoading(false)
    }
    if (ammount === "undefined" || ammount === "" || ammount === null) {
      notify("Fill The Amount", "danger");
      return setIsLoading(false)
    }
    if (
      printLocation === "undefined" ||
      printLocation === "" ||
      printLocation === null
    ) {
      notify("Fill The Print Location", "danger");
      return setIsLoading(false)
    }
    if (typeOfBg === "undefined" || typeOfBg === "" || typeOfBg === null) {
      notify("Fill The Type Of Bg", "danger");
      return setIsLoading(false)
    }
    if (
      paybleLocation === "undefined" ||
      paybleLocation === "" ||
      paybleLocation === null
    ) {
      notify("Fill The Payble Location", "danger");
      return setIsLoading(false)
    }
    if (!bgText.length) {
      notify("Please upload bgText", "danger");
      setIsLoading(false);
      return;
    }
    if (!purchaseOrder.length) {
      notify("Upload The Purchase Order", "danger");
      setIsLoading(false);
      return;
    }


    let payload = {
      requesterName: requestName,
      requesterEmail: requesterMail,
      entity: entity,
      unit: requestUnit,
      approver: isValidApproverInfo ? approverInfo : undefined,
      applicantName: applicantName,
      applicantLocation: applicantLocation,
      beneficiaryName: beneficiaryName,
      beneficiaryLocation: beneficiaryLocation,
      expiryDate: expiryDate,
      currency: currency,
      claimExpiryDate: claimExpiryDate,
      amount: ammount,
      printLocation: printLocation,
      bgType: typeOfBg,
      payableLocation: paybleLocation,
      bgText: JSON.stringify(bgText),
      purchaseOrder: JSON.stringify(purchaseOrder),
      requestorComment: requesterDetailComments !== undefined && requesterDetailComments !== null && requesterDetailComments !== "" ? requesterDetailComments : undefined,
      approverComment: approverDetailsComment!== undefined && approverDetailsComment !== null && approverDetailsComment !== "" ? approverDetailsComment : undefined,
      bankGuaranteeComment: bankGuaranteeComment!== undefined && bankGuaranteeComment !== null && bankGuaranteeComment !== "" ? bankGuaranteeComment : undefined,
      bankChargesComments: bankChargesComment !== undefined && bankChargesComment !== null && bankChargesComment !== "" ? bankChargesComment : undefined,
      attachmentsComment: attachmentComment!== undefined && attachmentComment !== null && attachmentComment !== "" ? attachmentComment : undefined,
      lineOfService: division,
      type: "submit"

    }
    await mainStore.createRequestorBgInssuance(payload, (response) => {
      if (response?.data?.meta?.status === "success") {
        notify(response?.data?.meta?.message);
        navigate("/requestor-journey-dashboard")
        setIsLoading(false)
      } else {
        notify(response?.data?.meta?.message, "danger");
        setIsLoading(false)
      }
    },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false)
      }
    );
  };


  const saveDetails = async () => {
  setIsLoading(true)
    let payload = {
      requesterName: requestName !== undefined && requestName !== null && requestName !== "" ? requestName : undefined,
      requesterEmail: requesterMail !== undefined && requesterMail !== null && requesterMail !== "" ? requesterMail : undefined,
      entity: entity !== undefined && entity !== null && entity !== "" ? entity : undefined,
      unit: requestUnit !== undefined && requestUnit !== null && requestUnit !== "" ? requestUnit : undefined,
      approver: isValidApproverInfo ? approverInfo : undefined,
      applicantName: applicantName !== undefined && applicantName !== null && applicantName !== "" ? applicantName : undefined,
      applicantLocation: applicantLocation !== undefined && applicantLocation !== null && applicantLocation !== "" ? applicantLocation : undefined,
      beneficiaryName: beneficiaryName !== undefined && beneficiaryName !== null && beneficiaryName !== "" ? beneficiaryName : undefined,
      beneficiaryLocation: beneficiaryLocation !== undefined && beneficiaryLocation !== null && beneficiaryLocation !== "" ? beneficiaryLocation : undefined,
      expiryDate: expiryDate !== undefined && expiryDate !== null && expiryDate !== "" ? expiryDate : undefined,
      currency: currency !== undefined && currency !== null && currency !== "" ? currency : undefined,
      claimExpiryDate: claimExpiryDate !== undefined && claimExpiryDate !== null && claimExpiryDate !== "" ? claimExpiryDate : undefined,
      amount: ammount !== undefined && ammount !== null && ammount !== "" ? ammount : undefined,
      printLocation: printLocation !== undefined && printLocation !== null && printLocation !== "" ? printLocation : undefined,
      bgType: typeOfBg !== undefined && typeOfBg !== null && typeOfBg !== "" ? typeOfBg : undefined,
      payableLocation: paybleLocation !== undefined && paybleLocation !== null && paybleLocation !== "" ? paybleLocation : undefined,
      bgText: JSON.stringify(bgText),
      purchaseOrder: JSON.stringify(purchaseOrder),
      requestorComment: requesterDetailComments !== undefined && requesterDetailComments !== null && requesterDetailComments !== "" ? requesterDetailComments : undefined,
      approverComment: approverDetailsComment!== undefined && approverDetailsComment !== null && approverDetailsComment !== "" ? approverDetailsComment : undefined,
      bankGuaranteeComment: bankGuaranteeComment!== undefined && bankGuaranteeComment !== null && bankGuaranteeComment !== "" ? bankGuaranteeComment : undefined,
      bankChargesComments: bankChargesComment !== undefined && bankChargesComment !== null && bankChargesComment !== "" ? bankChargesComment : undefined,
      attachmentsComment: attachmentComment!== undefined && attachmentComment !== null && attachmentComment !== "" ? attachmentComment : undefined,
      lineOfService: division,
      type: "save"
    };

    await mainStore.createRequestorBgInssuance(payload, (response) => {
      if (response?.data?.meta?.status === "success") {
        navigate("/requestor-journey-dashboard")
        notify(response?.data?.meta?.message);
        setIsLoading(false)
      } else {
        notify(response?.data?.meta?.message, "danger");
        setIsLoading(false)
      }
    },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
        setIsLoading(false)
      }
    );
  }

  const fetchApproverDetail = async (lineOfService) => {
    if (!lineOfService) {
      return;
    }

    let payload = {
      lineOfService
    };
    await mainStore.getApprovers(payload, (response) => {
      if (response?.data?.meta?.status === "success") {
        setApproverList(response?.data?.data?.users)
      } else {
        notify(response?.data?.meta?.message, "danger");
      }
    },
      (error) => {
        notify(error?.response?.data?.meta?.message, "danger");
      }
    );
  }
  const handleInputChange = (e) => {
    let value = e.target.value
    setDivision(value)
    fetchApproverDetail(value)
    setApproverInfo([
      { approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L1", approverId: "" },
      { approverName: "", approverDepartment: "", approverEmail: "", approverLevel: "L2", approverId: "" }
  ]);
  }

  useEffect(() => {
    fetchLos()
    if (division) {
      fetchApproverDetail(division);
    }
  }, []);


  const fetchLos = async() =>{
    await mainStore.getLos((status , res) =>{
      if (res?.meta?.status === "success") {
        setLineOfService(res?.data?.allLos);
      } else {
        notify(res?.meta?.message, "danger")
      }
    },
      (error) => {
        notify(error?.response?.meta?.message, "danger")
      }
    );
  }


  return (
    <div className="main-container">
      {isLoading && <Loader />}
      <div className="right-view">
        <Header title={"BG > Issuance"} />
        <div className="row">
          <div className="col-md-12">
            <CustomCard
              cardtitle={"Requester"}
              cardboldtitle={"Details"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="Request Number"
                      value={requestNumber}
                      onChange={(e) => setRequestNumber(e.target.value)}
                      placeholder="Request Number"

                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      readOnly={true}
                      label="Requester Name"
                      value={requestName}
                      placeholder="Requester Name"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      readOnly={true}
                      label="Requester Mail ID"
                      value={requesterMail}
                      placeholder="Requester mail ID"
                    />
                  </div>

                  <div className="col-md-3">
                    <div className="custominput-container">
                      <div className="custom-input">
                        <label htmlFor="" >Entity </label>
                        <div style={{ position: 'relative' }}>
                          <select
                            className="form-control"
                            value={requestEntity.name}
                            onChange={(e) => { setEntity(e.target.value) }}
                          >
                            <option value="">Select Entity</option>
                            {requestEntity.map((entity, index) => (
                              <option key={index} value={entity.name}>
                                {entity.name}
                              </option>
                            ))}
                          </select>
                          <i className="fas fa-chevron-down approver-drop-down"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Unit"
                      value={requestUnit}
                      onChange={(e) => setRequestUnit(e.target.value)}
                      placeholder="Unit"
                    />
                  </div>

                  <div className="col-md-3">
                    <div className="custominput-container">
                      <div className="custom-input">
                        <label htmlFor="" >Line Of Service  <span style={{ color: 'red' }}>*</span></label>
                        <div style={{ position: 'relative' }}>
                          <select
                            className="form-control"
                            value={division}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Line Of Service</option>
                            {lineOfService.map((item, index) => (
                              <option key={index} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <i className="fas fa-chevron-down approver-drop-down"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <CustomInput
                      label="Comment"
                      value={requesterDetailComments}
                      onChange={(e) => setRequesterDetailComments(e.target.value)}
                      placeholder="Comment"
                    />
                  </div>

                </div>
              }
            />
          </div>

          <div className="col-md-12">
            <CustomCard
              cardtitle={"Approver"}
              cardboldtitle={"Details"}
              cardbody={
                <div>
                  {approverInfo.map((approver, index) => (
                    <div key={index} className="row">
                      <div className="col-md-4">
                        <div className="custominput-container">
                          <div className="custom-input">
                            <label htmlFor={`approverName${index}`} >
                              {index === 0  && <>Approver Purchase <span style={{ color: 'red' }}>*</span></> }
                              {index === 1  && <>Approver Finance <span style={{ color: 'red' }}>*</span></> }
                              
                            </label>
                            <div style={{ position: 'relative' }}>
                              <select
                                id={`approverName${index}`}
                                className="form-control"
                                value={approver.approverName}
                                onChange={(e) => {
                                  const selectedApprover = approverList.find(approver => approver.name === e.target.value);
                                  handleApproverChange(index, "approverName", e.target.value);
                                  handleApproverChange(index, "approverId", selectedApprover.id);
                                  handleApproverChange(index, "approverEmail", selectedApprover.email);
                                  handleApproverChange(index, "approverDepartment", selectedApprover.user_department);
                                }}
                              >
                                <option value="">Select Approver</option>
                                {index === 0 && approverList.filter(approver => approver.user_department === "Purchase").map((filteredApprover, filteredIndex) => (
                                  <option key={filteredIndex} value={filteredApprover.name}>
                                    {filteredApprover.name}
                                  </option>
                                ))}
                                {index === 1 && approverList.filter(approver => approver.user_department === "Finance").map((filteredApprover, filteredIndex) => (
                                  <option key={filteredIndex} value={filteredApprover.name}>
                                    {filteredApprover.name}
                                  </option>
                                ))}
                              </select>
                              <i className="fas fa-chevron-down approver-drop-down"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <CustomInput
                          require={true}
                          readOnly={true}
                          label="Department"
                          value={approver.approverDepartment}
                          onChange={(e) => handleApproverChange(index, "approverDepartment", e.target.value)}
                          placeholder="Department"
                        />
                      </div>
                      <div className="col-md-4">
                        <CustomInput
                          require={true}
                          readOnly={true}
                          label="Mail ID"
                          value={approver.approverEmail}
                          onChange={(e) => handleApproverChange(index, "approverEmail", e.target.value)}
                          placeholder="Mail ID"
                        />
                      </div>
                    </div>
                  ))}
                  <div className="col-md-4">
                    <CustomInput
                      label="Comment"
                      value={approverDetailsComment}
                      onChange={(e) => setApproverDetailsComment(e.target.value)}
                      placeholder="Comment"
                    />
                  </div>
                </div>

              }
            />
          </div>

          <div className="col-md-12">
            <CustomCard
              cardtitle={"Bank "}
              cardboldtitle={"Details"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="Bank Name"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                      placeholder="Bank Name"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Bank Account Number"
                      readOnly={true}
                      id="Bank Account Number"
                      value={bankAccountNumber}
                      onChange={(e) => setBankAccountNumber(e.target.value)}
                      placeholder="Bank Account Number"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Bank GL Code"
                      readOnly={true}
                      value={bankGlCode}
                      onChange={(e) => setBankGlCode(e.target.value)}
                      placeholder="Bank GL Code"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="Comment"
                      value={bankDetailComment}
                      onChange={(e) => setBankDetailComment(e.target.value)}
                      placeholder="Comment"
                    />
                  </div>
                </div>
              }
            />
          </div>

          <div className="col-md-12">
            <CustomCard
              cardtitle={"Bank Guarantee"}
              cardboldtitle={"Details"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput
                      label="Reference Number"
                      readOnly={true}
                      id="Reference Number"
                      value={refrenceNumber}
                      onChange={(e) => setRefrenceNumber(e.target.value)}
                      placeholder="Reference Number"
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="custominput-container">
                      <div className="custom-input">
                        <label htmlFor="" >Applicant Name<span style={{ color: 'red' }}>*</span> </label>
                        <div style={{ position: 'relative' }}>
                          <select
                            className="form-control"
                            value={applicantName}
                            onChange={(e) => { setApplicantName(e.target.value) }}
                          >
                            <option value="">Select Applicant Name</option>
                            {requestEntity.map((entity, index) => (
                              <option key={index} value={entity.name}>
                                {entity.name}
                              </option>
                            ))}
                          </select>
                          <i className="fas fa-chevron-down approver-drop-down"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Applicant Location"
                      id="Applicant Location"
                      value={applicantLocation}
                      onChange={(e) => setApplicantLocation(e.target.value)}
                      placeholder="Applicant Location"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Beneficiary Name"
                      id="Beneficiary Name"
                      value={beneficiaryName}
                      onChange={(e) => setBeneficiaryName(e.target.value)}
                      placeholder="Beneficiary Name"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Beneficiary Location"
                      id="Beneficiary Location"
                      value={beneficiaryLocation}
                      onChange={(e) => setBeneficiaryLocation(e.target.value)}
                      placeholder="Beneficiary Location"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Issuance Date"
                      type="date"
                      readOnly={true}
                      value={inssuanceDate}
                      onChange={(e) => setInssuanceDate(e.target.value)}
                      placeholder="Issuance Date"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Expiry Date"
                      type="date"
                      value={expiryDate?.toLocaleString("en-GB")}
                      onChange={(e) => setExpiryDate(e.target.value)}
                      placeholder="Expiry Date"
                      min={new Date().toISOString()?.split('T')[0]}
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="custominput-container">
                      <div className="custom-input">
                        <label htmlFor="" >Currency </label>
                        <div style={{ position: 'relative' }}>
                          <select
                            className="form-control"
                            value={currency}
                            onChange={(e) => setCurrency(e.target.value)}
                          >
                            <option value="">Select Currency</option>
                            {currencyList.map((item, index) => (
                              <option key={index} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <i className="fas fa-chevron-down approver-drop-down"></i>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Claim Expiry Date"
                      type="date"
                      min={expiryDate}
                      value={claimExpiryDate?.toLocaleString("en-GB")}
                      onChange={(e) => setClaimExpiryDate(e.target.value)}
                      placeholder="Claim Expiry Date"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Amount"
                      id="Amount"
                      value={ammount}
                      onChange={(e) => setAmmount(e.target.value)}
                      placeholder="Amount"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Print Location"
                      id="Print Location"
                      value={printLocation}
                      onChange={(e) => setPrintLoation(e.target.value)}
                      placeholder="Print Location"
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="custominput-container">
                      <div className="custom-input">
                        <label htmlFor="" >Type of BG </label>
                        <div style={{ position: 'relative' }}>
                          <select
                            className="form-control"
                            value={typeOfBg}
                            onChange={(e) => setTypeOfBg(e.target.value)}
                          >
                            <option value="">Select Type of BG</option>
                            {typeOfBgList.map((item, index) => (
                              <option key={index} value={item.name}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <i className="fas fa-chevron-down approver-drop-down"></i>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Payable Location"
                      id="Payable Location"
                      value={paybleLocation}
                      onChange={(e) => setPaybleLocation(e.target.value)}
                      placeholder="Payable Location"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Comment"
                      value={bankGuaranteeComment}
                      onChange={(e) => setBankGuaranteeComment(e.target.value)}
                      placeholder="Comment"
                    />
                  </div>
                </div>
              }
            />
          </div>

          <div className="col-md-12">
            <CustomCard
              cardtitle={"Bank Charges"}
              cardboldtitle={"Details"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput
                      label="BG Charges"
                      readOnly={true}
                      id="BG Charges"
                      value={bgCharges}
                      onChange={(e) => setBgCharges(e.target.value)}
                      placeholder="BG Charges"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="GST"
                      id="GST"
                      readOnly={true}
                      value={gst}
                      onChange={(e) => setGst(e.target.value)}
                      placeholder="GST"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Stamp Charges"
                      id="Stamp Charges"
                      readOnly={true}
                      value={stampcharges}
                      onChange={(e) => setStampCharges(e.target.value)}
                      placeholder="Stamp Charges"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="BG Charges GL Code"
                      id="BG Charges GL Code"
                      value={bgChargesGlCode}
                      onChange={(e) => setBgChargesGlCode(e.target.value)}
                      placeholder="BG Charges GL Code"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      readOnly={true}
                      label="GST GL Code"
                      id="GST GL Code"
                      value={gstGlCode}
                      onChange={(e) => setGstGlCode(e.target.value)}
                      placeholder="GST GL Code"
                    />
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                     readOnly={true}
                      label="Comment"
                      value={bankChargesComment}
                      onChange={(e) => setBankChargesComment(e.target.value)}
                      placeholder="Comment"
                    />
                  </div>
                </div>
              }
            />
          </div>

          <div className="col-md-12">
            <CustomCard
              cardboldtitle={"Attachments"}
              cardbody={
                <div className="row">
                  <div className="col-md-3">
                    <CustomInput
                      label="BG Text"
                      placeholder="BG Text"
                      type="file"
                      require={true}
                      onChange={handleBgText}
                    />
                    <div className="uploaded-images">
                      {bgText.map((bgText, index) => (
                        <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: '5px' }} onClick={() => handleBgTextdownload(bgText)} className="url-download">
                          {bgText}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      require={true}
                      label="Purchase Order/ RFP"
                      type="file"
                      onChange={handlePurchaseOrder}
                    />
                    <div className="uploaded-images">
                      {purchaseOrder.map((order, index) => (
                        <div key={index} style={{ cursor: "pointer", color: "#65a6fa", marginBottom: '5px' }} onClick={() => handlePurchasedownload(order)} className="url-download">
                          {order}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <CustomInput
                      label="Comment"
                      value={attachmentComment}
                      onChange={(e) => setAttachmentComment(e.target.value)}
                      placeholder="Comment"
                    />
                  </div>
                </div>
              }
            />
          </div>

        { Viewer !=="Viewer" && <div className="col-md-12 text-end">
            <CustomButton style={"btn--outline me-3"} children={"Save"} onClick={saveDetails} />
            <CustomButton
              children={"Submit for approval"}
              onClick={submitForApproval}
            />
          </div>}
        </div>
      </div>
    </div>
  );
};

export default Issuance;
